import React, { useState, useEffect } from 'react'

import Skills from '../Skills/Skills'
// import Carosel from '../MiniComponents/Carosel'
// import HomePageSectionMusic from '../Music/HomePageSectionMusic'
// import HomePageSectionRender from '../Rendering/HomePageSectionRender'
// import HomePageSectionDev from '../Portfolio/HomePageSectionDev'
import StandoutDisplay from '../MiniComponents/StandoutDisplay'
import ContactForm from '../Contact/ContactForm'
import ProjectCards from '../MiniComponents/ProjectCards'
import Portfolio from '../Data/Portfolio'
import MusicProjects from '../Data/MusicProjects'
import EducationData from '../Data/EducationData'
import RenderProject from '../Data/RenderProject'
// import NavbarChatComponent from '../AI/NavbarChatComponent'

const Home = ({dimensions,default_width,navigate}) => {
	const [ loadNum, setLoadNum ] = useState(0)

	useEffect(()=>{
		let count = 0
		let interval = setInterval(()=>{
			if(count > 10){
				clearInterval(interval)
			}
			count+=1
			setLoadNum(count)
		},400)
	},[])



	function projectClicked(e) {
		console.log(e)
		navigate(e)
	}
	function goToLink(e) {
		window.open(e, '_blank', 'noopener,noreferrer')
	}



	function navItem(text,link) {
		if(text === 'Home'){
			return <div style={{width:'25%'}} className='flexCenter'><div className='ggl_text_main_secondary ggl_text_main_color pointer flexCenter'
					style={{padding:8,border:'2px solid rgb(70,130,255)',backgroundImage:'linear-gradient(rgb(0,0,120),black)',borderRadius:40}}>
				<div style={{width:20}}></div>{text}<div style={{width:20}}></div>
			</div></div>
		}
		return <div style={{width:'25%'}} className='flexCenter'><div className='ggl_text_main_secondary ggl_text_main_color hoverOpacityUp pointer flexCenter'
				style={{padding:8,
					// border:'2px solid rgb(70,130,255)',backgroundImage:'linear-gradient(rgb(0,0,120),black)',
					borderRadius:40}} onClick={()=>{navigate(link)}}>
			<div style={{width:20}}></div>{text}<div style={{width:20}}></div>
		</div></div>
	}
	// function dotPoint(text){
	// 	return(
	// 		<div className='textCenter ggl_text_main_secondary ggl_text_secondary_color flexCenter'>
	// 		{text}.</div>
	// 	)
	// }


	function projectDisplay(hide,len,wide,array) {
		let num_of_cols = 3
		if(wide < 1100) num_of_cols = 2
		if(wide < 600) num_of_cols = 1
		let arr = []
		for(let i = 0; i < array.length; i++){
			arr.push(
				<div key={i} style={{width:wide/num_of_cols,marginBottom:wide/num_of_cols*0.06}} className='flexWidthCenter'>
					{array[i]['type'] === 'dev'
						? <ProjectCards data={Portfolio[array[i]['iteration']]} width={wide/num_of_cols-wide/num_of_cols*0.06} cardClicked={projectClicked} type={'dev'} goToLink={goToLink} key_={array[i]['iteration']} />
						: array[i]['type'] === 'music'
							? <ProjectCards data={MusicProjects[array[i]['iteration']]} width={wide/num_of_cols-wide/num_of_cols*0.06} cardClicked={projectClicked} type={'music'} goToLink={goToLink} key_={array[i]['iteration']} />
							: array[i]['type'] === 'education'
								? <ProjectCards data={EducationData[array[i]['iteration']]} width={wide/num_of_cols-wide/num_of_cols*0.06} cardClicked={projectClicked} type={'education'} goToLink={goToLink} key_={array[i]['iteration']} />
								: <ProjectCards data={RenderProject[array[i]['iteration']]} width={wide/num_of_cols-wide/num_of_cols*0.06} cardClicked={projectClicked} type={'archviz'} goToLink={goToLink} key_={array[i]['iteration']} />
					}
				</div>
			)
		}
		return <div className='flexLeft' style={{flexWrap:'wrap',width:wide}}>{arr}</div>
	}


	let nav_width = 700
	if(default_width < nav_width) nav_width = default_width
	let second_text_width = 720
	if(second_text_width > default_width - 60) second_text_width = default_width - 60
	let contact_width = 700
	if(dimensions.width - 40 < contact_width) contact_width = dimensions.width - 40
	return(
		<div className='ggl_text_main_secondary'>
			{/*<div className='flexCenter textCenter radialColorBackground homepage_hero_heightx'>
				<div style={{width:default_width-40}}>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					<div className='ggl_text_hero ggl_text_main_color overflow relative' style={{}}>
						<div className='textAnimateIn absolute textCenter' style={{width:default_width-40}}>Creativite <b className='textGradient'>Tech</b></div>
						<div className='noOpacity'>Creativite <b className='textGradient'>Tech</b></div>
					</div>
					<div className='space_height_40'></div>
					<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:second_text_width}}>
						<div className='textAnimateIn2 absolute textCenter' style={{width:second_text_width}}>Hi, I’m Liam. My passion lies in creativity applying technology across coding, music, and architectural rendering</div>
						<div className='noOpacity'>Hi, I’m Liam. My passion lies in creativity applying technology across coding, music, and architectural rendering</div>
					</div></div>

					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
				</div>
			</div>*/}


			{/*{loadNum > 0
				? <div>
					<div className='flexCenter absolute fadeInslow' style={{zIndex:2,width:dimensions.width,marginTop:-(default_width/7)/2}}>
						<div style={{height:default_width/7,width:default_width/7,borderRadius:100,boxShadow:'0px 0px 16px rgba(0,0,0,1)',border:'2px solid rgb(140,200,240)'}} className='flexCenter overflow'>
							<img src={liam_fudge_512} alt='cartoon_avatar' style={{}} className='fullWidth' />
						</div>
					</div>
					<Carosel width={default_width} dimensions={dimensions} navigate={navigate} />
				</div>
				: <div className='noOpacity'><Carosel width={default_width} dimensions={dimensions} navigate={navigate} /></div>
			}*/}





			{/*VERSION 2*/}

			{/*<NavbarChatComponent dimensions={dimensions} width={second_text_width} route={''} />*/}


			{/*<div className='flexCenter textCenterx radialColorBackground' style={{height:dimensions.height-80}}>
				<div style={{width:second_text_width}}>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					<div className='ggl_text_hero ggl_text_main_color overflow relative' style={{}}>
						<div className='textAnimateIn absolute textCenterx' style={{width:second_text_width}}>Creative <b className='textGradient'>Tech</b></div>
						<div className='noOpacity'>Creative <b className='textGradient'>Tech</b></div>
					</div>
					<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:second_text_width}}>
						<div className='textAnimateIn2 absolute textCenterx' style={{width:second_text_width}}>Hi, I’m Liam. I apply creative technology solutions to coding music and architectural rendering.</div>
						<div className='noOpacity'>Hi, I’m Liam. I apply creative technology solutions to coding music and architectural rendering.</div>
					</div></div>

					<div className='space_height_20'></div>
					<AIChat width={second_text_width} />
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
				</div>
			</div>*/}


			{/*<div className='space_height_60'></div>
			<div className='space_height_60'></div>
			<div className='space_height_60'></div>
			<div className='space_height_60'></div>*/}
			
			

			{/*<div className='flexCenter fadeInslower'>
				<div>
					<div className='ggl_text_main_secondary ggl_text_main_color textCenter uppercase'>About me</div>
					<div className='space_height_20'></div>
					{dotPoint('I have a huge passion for technology and coding')}
					<div className='space_height_10'></div>
					{dotPoint('It was always my dream to hear my music on the radio')}
					<div className='space_height_10'></div>
					{dotPoint('I got obsessed with photoreal 3D rendering and taught myself')}
					<div className='space_height_10'></div>
					{dotPoint('I spent a year with my brother and dad building our family home')}
					<div className='space_height_10'></div>
					{dotPoint(`I can't help but create things I see in my head`)}
				</div>
			</div>
			<div className='space_height_60'></div>*/}
			{/*<div className='space_height_60'></div>*/}

			<div className='textAnimateIn'></div>
			{0 !== 2
				? <div className='flexCenter fadeInslowerx fadeInslow' style={{height:80}}><div style={{width:nav_width}} className='flexCenter'>{navItem('Home','/')}{navItem('Code</>','/dev')}{navItem('Music','/music')}{navItem('Rendering','/rendering')}</div></div>
				: null
			}


			{/*<div className='space_height_40'></div>*/}
			<StandoutDisplay default_width={default_width} navigate={navigate} dimensions={dimensions} type={'self_education'} />
			<div className='space_height_60'></div>
			<StandoutDisplay default_width={default_width} navigate={navigate} dimensions={dimensions} type={'120million'} />
			<div className='space_height_40'></div>
			<div className='flexCenter'>{projectDisplay('',1000,default_width,[{'type':'dev','iteration':'noovers'},{'type':'music','iteration':'aboutyou'},{'type':'archviz','iteration':'modern_scottland'},])}</div>
			<div className='space_height_20'></div>
			<StandoutDisplay default_width={default_width} navigate={navigate} dimensions={dimensions} type={'code_and_music'} />
			<div className='space_height_60'></div>
			<StandoutDisplay default_width={default_width} navigate={navigate} dimensions={dimensions} type={'archviz'} />
			<div className='space_height_40'></div>
			<div className='flexCenter'>{projectDisplay('',1000,default_width,[{'type':'archviz','iteration':'dark_kitchen'},{'type':'dev','iteration':'songhatch'},{'type':'music','iteration':'bleeding'},])}</div>

			
			<div className='fullWidth ggl_box_backgroundx fadeInslow radialColorBackground'
					// style={{background:'rgb(12,18,26)'}}
					>
				<div className='space_height_60'></div>
				<div className='space_height_60'></div>
				<div className='space_height_60'></div>
				{loadNum > 1
					? <div>
						<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{}}>
							<div className='textAnimateIn absolute textCenter' style={{width:dimensions.width}}>My skillset</div>
							<div className='noOpacity'>My skillset</div>
						</div>
						<div className='space_height_60'></div>
					</div>
					: <div className='noOpacity'><div className='ggl_text_heading ggl_text_main_color overflow relative' style={{}}><div className='noOpacity'>My skills</div></div><div className='space_height_60'></div></div>
				}
				{loadNum > 2
					? <Skills width={default_width} dimensions={dimensions} />
					: <div className='noOpacity'><Skills width={default_width} dimensions={dimensions} /></div>
				}
				<div className='space_height_60'></div>
			</div>

			{/*<div className='space_height_60'></div>
			<div className='space_height_60'></div>

			{loadNum > 3
				? <div>
					<div className='flexCenter'><HomePageSectionDev width={default_width} dimensions={dimensions} navigate={navigate} /></div>
				</div>
				: <div className='noOpacity'><HomePageSectionDev width={default_width} dimensions={dimensions} navigate={navigate} /></div>
			}*/}
				

			{/*<div className='space_height_60'></div>
			<div className='space_height_60'></div>
			<div className='space_height_60'></div>

			<div className='textAnimateIn'></div>
			<div className='relative flexCenter'><HomePageSectionRender width={default_width} fullDisplay={false} navigate={navigate} /></div>

			<div className='space_height_60'></div>
			<div className='space_height_60'></div>
			<div className='space_height_60'></div>

			<div className='textAnimateIn'></div>
			<HomePageSectionMusic width={default_width} dimensions={dimensions} navigate={navigate} />*/}


			<div className='space_height_60'></div>
			<div className='space_height_60'></div>


			<div className='ggl_text_heading ggl_text_main_color overflow relative' style={{}}>
				<div className='textAnimateIn absolute textCenter' style={{width:dimensions.width}}>Contact me</div>
				<div className='noOpacity'>Contact me</div>
			</div>
			<div className='space_height_40'></div>
			<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:dimensions.width-80}}>
				<div className='textAnimateIn2 absolute textCenter' style={{width:dimensions.width-80}}>Feel free to reach out and and ask me whatever</div>
				<div className='noOpacity'>Feel free to reach out and and ask me whatever</div>
			</div></div>

			<div className='space_height_20'></div>
			<div className='flexCenter'>
				<ContactForm dimensions={{height:50,width:contact_width}} />
			</div>
			<div className='space_height_60'></div>
			<div className='space_height_60'></div>
			<div className='space_height_60'></div>

			{/*<div style={{height:1200}}></div>
			Home
			{toDos('Certificates')}
			{toDos('Portfolio')}
			{toDos('Bio')}
			{toDos('3D rendering')}
			{toDos('Music')}
			{toDos('Work History')}

			"Case studies"
			{toDos('allow navigation between the projects and a back button to go to all projects',true)}
			{toDos('Write out the information for all the websites using chatgpt',true)}
			{toDos('consider adding a high quality audio engine one in, and including Hans Zimmer?')}
			{toDos('gather images and different media',true)}
			{toDos('create an object for each case study that can layout and have info on the different elements(mobile picture, audio, video, ect.)')}
			{toDos('store the portfolio simple data and the main data in 2 different parts, so it doesnt slow down loading',true)}


			{toDos('Add example data',true)}
			{toDos('loop through the project routes',true)}
			{toDos('skills graphic',true)}
			{toDos('create portolio container template',true)}
			"Education"
			{toDos('build object for education data',true)}
			{toDos('build a disply component to show an education thing',true)}
			Audio
			{toDos('Play audio',true)}
			{toDos('have one function to control the playback, storing audio in useRef()',true)}
			{toDos('wav shape display',true)}
			{toDos('3 way slider',true)}
			{toDos('skills/passion/experience',true)}
			{toDos('write about each of the skills',true)}
			{toDos('make skills on their side so writing can be horizontal',true)}

			todos
			{toDos('make the slider redux in projects',true)}
			{toDos('make the renders clickable',true)}
			{toDos('allow renders to click back and fourth',true)}
			{toDos('get the size of the modal right with dimensions',true)}
			{toDos('make the music clickable with link',true)}
			{toDos('Layout the portfolio page',true)}
			{toDos('project page banner',true)}
			{toDos('Number of streams',true)}
			{toDos('look for good drafting work',true)}
			{toDos('gradient text color',)}
			{toDos('include andy marsh music in sites',true)}
			{toDos('need social media?',)}
			{toDos('make a cool banner with elements that move as the slider shifts (maybe too processing heavy)',true)}

			<div style={{marginTop:20}}>Tech</div>
			{toDos('Redux')}
			{toDos('Tests')}
			{toDos('performance')}

			<div style={{height:200}}></div>


			<div style={{height:200}}></div>

			<div className='flexCenter'><AudioFileDisplayer data={audioData[0]} width={700} /></div>
			<div className='flexCenter'><AudioFileDisplayer data={audioData[1]} width={700} /></div>
			<div style={{height:200}}></div>
			<div style={{height:200}}></div>*/}

		</div>
	)
}

export default Home