import React from 'react'

// import Portfolio from '../Data/Portfolio'
import StandoutDisplay from '../MiniComponents/StandoutDisplay'
import MusicProjects from '../Data/MusicProjects'
import HomePageSectionMusic from '../Music/HomePageSectionMusic'
// import MusicDisplayContainer from '../Music/MusicDisplayContainer'
import ProjectCards from '../MiniComponents/ProjectCards'

const MusicRoute = ({dimensions,default_width,navigate}) => {


	function projectClicked(e) {
		// navigate('/projects/'+e)
		window.open(MusicProjects[e]['link'], '_blank', 'noopener,noreferrer')
	}
	function goToLink(e) {
		window.open(e, '_blank', 'noopener,noreferrer')
	}



	function projectDisplay(hide,wide) {
		let num_of_cols = 3
		if(wide < 1100) num_of_cols = 2
		if(wide < 600) num_of_cols = 1
		let arr = []
		// let count = 0
		for(const [key, val] of Object.entries(MusicProjects)){
			arr.push(
				<div key={key} style={{width:wide/num_of_cols,marginBottom:wide/num_of_cols*0.06}} className='flexWidthCenter'>
					<ProjectCards data={val} width={wide/num_of_cols-wide/num_of_cols*0.06} cardClicked={()=>{projectClicked(key)}} type={'music'} goToLink={goToLink} key_={key} />
				</div>
			)
		}
		return <div className='flexLeft' style={{flexWrap:'wrap',width:wide}}>{arr}</div>
	}

	let second_text_width = 720
	if(second_text_width > default_width - 60) second_text_width = default_width - 60
	// let padding = 80
	// if(default_width < 600){
	// 	padding = 10
	// } else if(default_width < 600){
	// 	padding = 20
	// } else if(default_width < 800){
	// 	padding = 30
	// } else if(default_width < 1100){
	// 	padding = 40
	// } else if(default_width < 1200){
	// 	padding = 60
	// }

	return(
		<div>

			<div><HomePageSectionMusic width={dimensions.width} dimensions={dimensions} navigate={navigate} noText={true} /></div>

			<div className='flexCenter textCenter radialColorBackground homepage_hero_heightx fadeInslowerx'>
				<div style={{width:default_width-40}}>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					<div className='ggl_text_hero ggl_text_main_color overflow relative' style={{}}>
						<div className='textAnimateIn absolute textCenter' style={{width:default_width-40}}>Songwriting&<b className='textGradient'>Production</b></div>
						<div className='noOpacity'>Songwriting&<b className='textGradient'>Production</b></div>
					</div>
					<div className='space_height_40'></div>
					<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:second_text_width}}>
						<div className='textAnimateIn2 absolute textCenter' style={{width:second_text_width}}>Hi, I’m Liam. My passion lies in creativity applying technology across coding, music, and architectural rendering</div>
						<div className='noOpacity'>Hi, I’m Liam. My passion lies in creativity applying technology across coding, music, and architectural rendering</div>
					</div></div>

					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
				</div>
			</div>

			{/*<VisualDisplayExample />*/}

			<StandoutDisplay default_width={default_width} navigate={navigate} dimensions={dimensions} type={'120million'} />
			<div className='space_height_60'></div>
			<StandoutDisplay default_width={default_width} navigate={navigate} dimensions={dimensions} type={'studios'} />

			<div className='space_height_60'></div>
			<div className='space_height_60'></div>

			<div className='radialColorBackground'>
				<div className='flexCenter' style={{width:dimensions.width}}>
					<div style={{width:default_width}}>
						<div className='ggl_text_heading ggl_text_main_color overflow relative fullWidth'>
							<div className='textAnimateIn absolute textCenter boldz' style={{width:default_width}}>All <b className='textGradientLighter noBold'>Projects</b></div>
							<div className='noOpacity'>All<b className='textGradientLighter noBold'>Projects</b></div>
						</div>
						<div className='ggl_text_secondary_color overflow relative' style={{}}>
							<div className='textAnimateIn2 absolute textCenter' style={{width:default_width}}>Here are songs I have produced and studios I have worked with</div>
							<div className='noOpacity'>Here are songs I have produced and studios I have worked with</div>
						</div>
					</div>
				</div>
				<div className='space_height_40'></div>
				<div className='flexCenter'>
					<div>{projectDisplay('',default_width)}</div>
				</div>
				<div className='space_height_40'></div>

			</div>

		</div>
	)
}

export default MusicRoute







// const DisplayCard = ({data,width,cardClicked}) => {
// 	return(
// 		<div className='borderRadius fadeIn hoverOpacityDownx pointer relative borderRadius overflow ggl_border ggl_box_background hoverOpacityDown' style={{width:width}} onClick={cardClicked}>
// 			<div style={{padding:20}}>
// 				<div className='overflow borderRadiusx ggl_borderx absolute flexRight flexCenter' style={{width:width-40-2,height:(width-40)*0.5,backgroundImage:'linear-gradient(90deg,rgba(0,0,0,0.1),rgba(0,0,0,01))'}}>
// 					<div style={{boxShadow:'0px 0px 20px rgba(0,0,0,0.5)',borderRadius:6,width:(width-40)*0.5-10,height:(width-40)*0.5-10,background:'black'}} className='overflow flexCenter'>
// 						<img src={data['album_art']} alt='desktop_picture' style={{width:'100%'}} />
// 					</div>
// 					<div style={{width:5}}></div>
// 				</div>
// 				<div className='overflow borderRadiusx ggl_borderx' style={{width:width-40-2,height:(width-40)*0.5,boxShadow:'0px 0px 20px rgba(0,0,0,0.2)',borderRadius:6}}>
// 					<img src={data['background']} alt='desktop_picture' style={{width:width-40-2}} />
// 				</div>
// 				<div className='space_height_10'></div>
// 				<div className='ggl_text_smaller ggl_text_main_color boldx uppercasex' style={{textShadow:'0px 0px 20px black'}}>{data['artist']}</div>
// 				<div className='ggl_text_smaller ggl_text_secondary_color'>{data['song_name']}</div>
// 			</div>
// 		</div>
// 	)
// }






