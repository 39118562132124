import React from 'react'

import PortfolioData from '../Data/PortfolioData'
import Portfolio from '../Data/Portfolio'

const ProjectFullDisplay = ({key_,width}) => {


	function pageHeading() {
		let second_text_width = 720
		if(second_text_width > width - 60) second_text_width = width - 60
		return(
			<div className='flexCenter textCenter radialColorBackground homepage_hero_heightx fadeInslower'>
				<div style={{width:width-40}}>
					{/*<div className='space_height_60'></div>*/}
					<div className='space_height_60'></div>
					<div className='ggl_text_hero ggl_text_main_color overflow relative' style={{}}>
						<div className='textAnimateIn absolute textCenter' style={{width:width-40}}>{Portfolio[key_]['heading']}<b className='textGradient'></b></div>
						<div className='noOpacity'>{Portfolio[key_]['heading']}<b className='textGradient'></b></div>
					</div>
					<div className='space_height_40'></div>
					<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:second_text_width}}>
						<div className='textAnimateIn2 absolute textCenter' style={{width:second_text_width}}>{Portfolio[key_]['subheading']}</div>
						<div className='noOpacity'>{Portfolio[key_]['subheading']}</div>
					</div></div>

					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
				</div>
			</div>
		)
	}
	function heading(e) {
		return(
			<div className='ggl_text_subheading ggl_text_main_color boldx paragraphMarginBottom fadeInslow'>
				{e}
			</div>
		)
	}
	function subheading(e) {
		return(
			<div className='ggl_text_main_secondary uppercase ggl_text_main_color paragraphMarginBottom fadeInslow'>
				<div className='space_height_20'></div>
				{e}
			</div>
		)
	}
	function bold(e) {
		return(
			<div className='ggl_text_smaller ggl_text_secondary_color paragraphMarginBottomx bold fadeInslow'>
				{e}
				{/*<div className='space_height_10'></div>*/}
			</div>
		)
	}
	function body(e) {
		return(
			<div className='ggl_text_smaller ggl_text_secondary_color paragraphMarginBottom fadeInslow'>
				{e}
				{/*<div className='space_height_40'></div>*/}
			</div>
		)
	}


	function distributor() {
		const port = PortfolioData[key_]
		let arr = []
		for(let i = 0; i < port.length; i++){
			arr.push(
				<div key={i}>
					<div className='textAnimateIn'></div>
					{port[i]['type'] === 'heading' ? heading(port[i]['text']) : null}
					{port[i]['type'] === 'subheading' ? subheading(port[i]['text']) : null}
					{port[i]['type'] === 'bold' ? bold(port[i]['text']) : null}
					{port[i]['type'] === 'body' ? body(port[i]['text']) : null}
				</div>
			)
		}
		return arr
	}

	if(PortfolioData[key_] === undefined) return null
	return(
		<div style={{width:width}} className='radialColorBackground'>
			{pageHeading()}
			{distributor()}
		</div>
	)
}

export default ProjectFullDisplay