import algo_trading from '../../Images/certificates/algo_trading.jpg'
import computer_science from '../../Images/certificates/computer_science.jpg'
import datascience from '../../Images/certificates/datascience.jpg'
import deep_learning from '../../Images/certificates/deep_learning.jpg'
import javascript_ml from '../../Images/certificates/javascript_ml.jpg'
import nlp from '../../Images/certificates/nlp.jpg'
import python from '../../Images/certificates/python.jpg'
import web_developer from '../../Images/certificates/web_developer.jpg'

const EducationData = [
	{
		company: 'Udemy',
		link:'https://udemy-certificate.s3.amazonaws.com/image/UC-d2d37272-22dd-4200-82e5-44ab3177cee8.jpg?v=1741927629000',
		desktop: computer_science,
		name: 'Computer Science',
		subheading:'Master the Theory Behind Programming',
		mini:'Master the Theory Behind Programming',
		year:'2025'
	},
	{
		company: 'Udemy',
		link:'https://udemy-certificate.s3.amazonaws.com/image/UC-6cf1e138-2ed9-43cc-b76a-82c58a69b314.jpg?v=1723931928000',
		desktop: deep_learning,
		name: 'Deep Learning',
		subheading:'TensorFlow for Deep Learning Bootcamp',
		mini:'TensorFlow for Deep Learning Bootcamp',
		year:'2024'
	},
	{
		company: 'Udemy',
		link:'https://udemy-certificate.s3.amazonaws.com/image/UC-bbcabe5d-5565-4cb5-939d-af4b599a3a92.jpg?v=1633583360000',
		desktop: nlp,
		name: 'Transformers with NLP',
		subheading:'Natural Language Processing: NPL With Transformers in Python',
		year:'2021'
	},
	{
		company: 'Udemy',
		link:'https://udemy-certificate.s3.amazonaws.com/image/UC-3c7f8856-07bf-4f60-a0bf-f8be575ebc2d.jpg?v=1618044059000',
		desktop: python,
		name: 'Python',
		subheading:'Complete Python Developer in 2021: Zero to Mastery',
		year:'2021'
	},
	{
		company: 'Udemy',
		link:'https://udemy-certificate.s3.amazonaws.com/image/UC-b4ab6812-98bf-4776-ad57-37e9b16368ab.jpg?v=1619691479000',
		desktop: datascience,
		name: 'ML & DS',
		subheading:'Complete Machine Learning & Data Science Bootcamp 2021',
		year:'2021'
	},
	{
		company: 'Udemy',
		link:'https://udemy-certificate.s3.amazonaws.com/image/UC-LDLX4FW4.jpg?v=1569933538000',
		desktop: javascript_ml,
		name: 'Tensorflow',
		subheading:'Machine Learning in Javascript with Tensorflow.js',
		year:'2019'
	},
	{
		company: 'Udemy',
		link:'https://udemy-certificate.s3.amazonaws.com/image/UC-PIMJ8MAU.jpg?v=1533471881000',
		desktop: web_developer,
		name: 'Web Developer',
		subheading:'The Complete Web Developer in 2018: Zero to Mastery',
		year:'2018'
	},
	{
		company: 'Udemy',
		link:'https://udemy-certificate.s3.amazonaws.com/image/UC-5Y8QI8T5.jpg?v=1521070850000',
		desktop: algo_trading,
		name: 'Algorithmic Trading',
		subheading:'Algorithmic Trading In Forex: Create Your First Forex Robot',
		year:'2018'
	},
]

export default EducationData