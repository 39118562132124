import React from 'react'

const TextInput = ({val,type,placeholder,input_type,description,warningMessage,mobile,textOutput,width,height,paddingLeft,enterKeyPressed}) => {

	function checkForEnter(e){
		if(e.key === 'Enter' && val !== ''){
			enterKeyPressed()
		}
	}


	let className = 'textInput main_border_radius expand_widex borderRadius'
	if(type === warningMessage['type']) className = 'textInput input_error expand_widex borderRadius'
	return(
		<div>
			{description === '' ? null : <div className='label'>{description}</div> }
			{mobile === true
				? <input className={className} placeholder={placeholder} value={val} type={input_type}
						onChange={(e)=>{textOutput(e.target.value,type)}} onKeyDown={checkForEnter}
						style={{width:width-paddingLeft-8,height:height-8,fontSize:16,paddingLeft:paddingLeft}} id={type} />
				: <input className={className} placeholder={placeholder} value={val} type={input_type}
						onChange={(e)=>{textOutput(e.target.value,type)}} onKeyDown={checkForEnter}
						style={{width:width-paddingLeft-4,height:height-4,paddingLeft:paddingLeft}} id={type} />
			}
				
		</div>
	)
}

export default TextInput