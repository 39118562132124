import React from 'react'

import ARROW from '../../Images/arrow-right-white.svg'

const ProjectCards = ({data,width,cardClicked,type,goToLink,key_}) => {

	if(type === 'music'){
		return(
			<div className='borderRadius fadeIn hoverOpacityDownx pointerx relative borderRadius overflow ggl_borderx ggl_box_background hoverOpacityDownx' style={{width:width}}>
				<div style={{padding:20}}>
					<div className='overflow borderRadiusx ggl_borderx absolute flexRight flexCenter' style={{width:width-40-2,height:(width-40)*0.5,backgroundImage:'linear-gradient(90deg,rgba(0,0,0,0.1),rgba(0,0,0,01))'}}>
						<div style={{boxShadow:'0px 0px 20px rgba(0,0,0,0.5)',borderRadius:6,width:(width-40)*0.5-10,height:(width-40)*0.5-10,background:'black'}} className='overflow flexCenter'>
							<img src={data['album_art']} alt='desktop_picture' style={{width:'100%'}} />
						</div>
						<div style={{width:5}}></div>
					</div>
					<div className='overflow borderRadiusx ggl_borderx' style={{width:width-40-2,height:(width-40)*0.5,boxShadow:'0px 0px 20px rgba(0,0,0,0.2)',borderRadius:6}}>
						<img src={data['background']} alt='desktop_picture' style={{width:width-40-2}} />
					</div>
					<div className='space_height_10'></div>
					<div className='ggl_text_smaller ggl_text_main_color boldx uppercasex' style={{textShadow:'0px 0px 20px black'}}>{data['artist']}</div>
					<div className='ggl_text_smaller ggl_text_secondary_color'>{data['song_name']}</div>
					<div className='ggl_text_smaller ggl_text_secondary_color'>{data['role']}</div>
					<div className='space_height_10'></div>
					<div className='ggl_border ggl_text_main_color ggl_text_smallest flexCenter pointer hoverBackgroundLightenMore' style={{borderRadius:20,height:28,width:28*5,border:'1px solid rgb(60,60,70)'}}
							onClick={()=>{goToLink(data['link'])}}
							>
						{data['button']}
						<img src={ARROW} alt='ARROW' style={{height:28*0.47,marginLeft:4,marginTop:2}} />
					</div>
				</div>
			</div>
		)
	} else if(type === 'education'){
		return(
			<div className='borderRadius fadeInslowerx hoverOpacityDownx pointerx relative borderRadius overflow ggl_borderx ggl_box_background hoverOpacityDownx' style={{width:width}}>
				<div style={{padding:20}}>
					<div className='overflow borderRadiusx flexCenter' style={{width:width-40-2,height:(width-40)*0.5,boxShadow:'0px 0px 20px rgba(0,0,0,0.2)',borderRadius:6,background:'white'}}>
						<img src={data['desktop']} alt='desktop_picture' style={{height:(width-40)*0.5}} />
					</div>
					<div className='space_height_10'></div>
					<div className='ggl_text_smaller ggl_text_main_color boldx uppercasex' style={{textShadow:'0px 0px 20px black'}}>{data['name']}</div>
					<div className='ggl_text_smaller ggl_text_secondary_color'>{data['subheading']}</div>
					<div className='space_height_10'></div>
				</div>
			</div>
		)
	} else if(type === 'archviz'){
		return(
			<div className='borderRadius fadeIn hoverOpacityDownx pointerx relative borderRadius overflow ggl_borderx ggl_box_background hoverOpacityDownx' style={{width:width}}>
				<div style={{padding:20}}>
					<div className='overflow borderRadiusx ggl_borderx' style={{width:width-40-2,height:(width-40)*0.5,boxShadow:'0px 0px 20px rgba(0,0,0,0.2)',borderRadius:6}}>
						<img src={data['thumb']} alt='desktop_picture' style={{width:width-40-2}} />
					</div>
					<div className='space_height_10'></div>
					<div className='ggl_text_smaller ggl_text_main_color boldx uppercasex' style={{textShadow:'0px 0px 20px black'}}>{data['heading']}</div>
					<div className='ggl_text_smaller ggl_text_secondary_color'>{data['subheading']}</div>
					<div className='space_height_10'></div>
					<div className='ggl_border ggl_text_main_color ggl_text_smallest flexCenter pointer hoverBackgroundLightenMore' style={{borderRadius:20,height:28,width:28*5,border:'1px solid rgb(60,60,70)'}}
							onClick={()=>{cardClicked('/rendering')}}
							>
						More info
						<img src={ARROW} alt='ARROW' style={{height:28*0.47,marginLeft:4,marginTop:2}} />
					</div>
				</div>
			</div>
		)
	}
	return(
		<div className='borderRadius fadeIn hoverOpacityDownx pointerx relative borderRadius overflow ggl_borderx ggl_box_background hoverOpacityDownx' style={{width:width}}>
			<div style={{padding:20}}>
				<div className='absolute flexCenter pointer ggl_text_main_color ggl_text_smaller opacityUpFromZero bold' style={{width:width-40-2,height:(width-40)*0.5,background:'rgba(0,0,0,0.5)',zIndex:1,textShadow:'0px 0px 7px rgba(0,0,0,0.8)'}} onClick={()=>{cardClicked(`/projects/${key_}`)}}>Click to read more</div>
				<div className='overflow borderRadiusx ggl_borderx' style={{width:width-40-2,height:(width-40)*0.5,boxShadow:'0px 0px 20px rgba(0,0,0,0.2)',borderRadius:6}}>
					<img src={data['desktop']} alt='desktop_picture' style={{width:width-40-2}} />
				</div>
				<div className='space_height_10'></div>
				<div className='ggl_text_smaller ggl_text_main_color boldx uppercasex' style={{textShadow:'0px 0px 20px black'}}>{data['heading']}</div>
				<div className='ggl_text_smaller ggl_text_secondary_color'>{data['subheading']}</div>
				<div className='space_height_10'></div>
				<div className='ggl_border ggl_text_main_color ggl_text_smallest flexCenter pointer hoverBackgroundLightenMore' style={{borderRadius:20,height:28,width:28*5,border:'1px solid rgb(60,60,70)'}}
						onClick={()=>{goToLink(data['link'])}}
						>
					Go to site
					<img src={ARROW} alt='ARROW' style={{height:28*0.47,marginLeft:4,marginTop:2}} />
				</div>
			</div>
		</div>
	)
}

export default ProjectCards