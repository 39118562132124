import React from 'react'

import Portfolio from '../Data/Portfolio'
import EducationData from '../Data/EducationData'
import MusicProjects from '../Data/MusicProjects'
import RenderProject from '../Data/RenderProject'

import PATTERN from '../../Images/pattern_eg11.png'
import ARROW from '../../Images/arrow-right-white.svg'

const StandoutDisplay = ({default_width,navigate,dimensions,type}) => {


	function button1Clicked(e) {
		if(e.includes('/projects/') && window.location.pathname !== '/dev'){
			navigate('/dev')
		} else if(e.includes('/music') && window.location.pathname !== '/music'){
			navigate('/music')
		} else {
			navigate(e)
		}
	}


	function elementTextDisplayer(wide) {
		let data = {heading:'Where code and music converge',subheading:'The lines between music and code began to blur, only for my music generation system to emerge from the haze.',button:'Learn more',link:'/projects/songhatch'}
		if(type === 'social_network'){
			data = {heading:'A New Kind of Social Network',subheading:'Noovers is a social media platform designed with a focus on creating an inclusive and supportive environment for adults on the autism spectrum.',button:'Learn more',link:'/projects/noovers'}
		} else if(type === 'self_education'){
			data = {heading:'Self motivated',subheading:`I love to learn. When I'm pre-occupied by something, I have to learn about it. I have always been motivated to develop a complete understanding of how something I’m interested in works.`,button:'View all',link:'/education'}
		} else if(type === 'studios'){
			data = {heading:'Studios I have Collaborated with',subheading:`I was signed signed to Artist Publishing Group under Atlantic Records, and have done music for Hans Zimmers "Bleeding Fingers"`,button:'Learn more',link:'/music'}
		} else if(type === '120million'){
			data = {heading:'+120M Streams Across Platforms',subheading:`The biggest-selling single I’ve worked on was for the recording artist Trey Songz. In its first week of release, it was the most added song on rhythmic and urban radio.`,button:'Learn more',link:'/music'}
		} else if(type === 'archviz'){
			data = {heading:'Photoreal Architectural Rendering',subheading:`I love the challenge of creating architectural renders that are indistinguishable from photos.`,button:'Learn more',link:'/rendering'}
		}
		return(
			<div style={{width:wide}}>
				<div className='ggl_text_subheading ggl_text_main_color'>{data['heading']}</div>
				<div className='space_height_20'></div>
				<div className='ggl_text_smaller ggl_text_secondary_color'>{data['subheading']}</div>
				<div className='space_height_20'></div>
				<div className='actionColor ggl_text_main_color ggl_text_smaller flexCenter pointer hoverOpacityDown' style={{borderRadius:20,height:40,width:180}} onClick={()=>{button1Clicked(data['link'])}}>
					{data['button']}
					<img src={ARROW} alt='ARROW' style={{height:16,marginLeft:4,marginTop:2}} />
				</div>
			</div>
		)
	}
	function miniElementDisplayer(wide,num) {
		let data = [{data:Portfolio['songhatch'],button:'Learn more',link:'/projects/songhatch'},{data:Portfolio['catalogue'],button:'Learn more',link:'/projects/catalogue'}]
		if(type === 'social_network'){
			data = [{data:'',button:'Learn more',link:'/projects/noovers'}]
		} else if(type === 'self_education'){
			data = [{data:EducationData[0],button:'See details',link:'/education'},{data:EducationData[1],button:'See details',link:'/education'}]
		} else if(type === 'studios'){
			data = [{data:MusicProjects['apg'],button:'See details',link:'/education'},{data:MusicProjects['bleeding'],button:'See details',link:'/education'}]
		}

		let padding = 16
		let imageWidth = 180
		let buttonHigh = 34
		if(wide < 500){
			padding = 10
			imageWidth = 160
			buttonHigh = 28
		}
		if(dimensions.width < 500){
			imageWidth = 120
		} else if(dimensions.width < 700){
			imageWidth = 140
		} else if(dimensions.width < 900){
			imageWidth = 150
		}
		return(
			<div className='borderRadius relative borderRadius overflow ggl_borderx ggl_box_background' style={{width:wide,background:'rgb(20,20,28)',border:'1px solid rgb(26,26,44)',boxShadow:'0px 0px 30px rgba(0,0,0,0.3)'}}>
				<div style={{padding:padding}} className='flexLeft'>
					<div style={{width:imageWidth}}><div style={{width:imageWidth,height:imageWidth*0.8,border:'2px solid black',background:'black',boxShadow:'0px 0px 20px rgba(0,0,0,0.5)'}} className='flexCenter overflow borderRadius'>
						<img src={data[num]['data']['desktop']} alt='' style={{height:'100%'}} />
					</div></div>
					<div style={{width:(padding*2)}}></div>
					<div style={{width:wide-(padding*2)-imageWidth-(padding*2)}} className='flexCenter'>
						<div className='fullWidth'>
							<div className='ggl_text_main_secondary ggl_text_main_color boldx'>{data[num]['data']['name']}</div>
							{/*<div className='space_height_10'></div>*/}
							<div className='ggl_text_smaller ggl_text_secondary_color'>{data[num]['data']['mini']}</div>
							<div className='space_height_20'></div>
							<div className='ggl_border ggl_text_main_color ggl_text_smallest flexCenter pointer hoverBackgroundLightenMore' style={{borderRadius:20,height:buttonHigh,width:buttonHigh*5,border:'1px solid rgb(60,60,70)'}} onClick={()=>{navigate(data[num]['link'])}}>
								{data[num]['button']}
								<img src={ARROW} alt='ARROW' style={{height:buttonHigh*0.47,marginLeft:4,marginTop:2}} />
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
	function bigImageDisplay(wide) {
		if(type === 'social_network'){
			return <img src={Portfolio['noovers']['desktop']} alt='noovers_pic' className='fullWidth' />
		} else if(type === '120million'){
			return <div style={{width:wide,height:wide*0.8}} className='overflow flexCenter'><img src={MusicProjects['aboutyou']['desktop']} alt='noovers_pic' className='fullWidth' /></div>
		} else if(type === 'archviz'){
			return <div style={{width:wide,height:wide*0.8}} className='overflow flexCenter'><img src={RenderProject['modern_suburbs']['src']} alt='noovers_pic' className='fullHeight' /></div>
		}
	}


	let second_text_width = 720
	if(second_text_width > default_width - 60) second_text_width = default_width - 60
	let padding = 80
	if(default_width < 600){
		padding = 10
	} else if(default_width < 600){
		padding = 20
	} else if(default_width < 800){
		padding = 30
	} else if(default_width < 1100){
		padding = 40
	} else if(default_width < 1200){
		padding = 60
	}
	
	let third_width = (default_width-padding*2)/3
	let two_third_width = (default_width-padding*2)/3*2
	if(default_width < 900){
		third_width = (default_width-padding*2)
		two_third_width = (default_width-padding*2)
	}

	// type={'code_and_music'} type={'social_network'} type={'self_education'} type={'studios'} type={'120million'} type={'archviz'}

	if(type === 'social_network' || type === '120million' || type === 'archviz'){
		return(
			<div className='flexCenter fadeInslower'>
				<div style={{width:default_width}} className='borderRadius relative overflow ggl_borderx ggl_box_background'>
					<div style={{padding:padding}}>
						{default_width < 900
							? <div className='flexLeftx' style={{flexWrap:'wrap'}}>
								<div style={{width:third_width}} className='flexCenter'>
									{elementTextDisplayer(third_width)}
								</div>
								<div className='space_height_40'></div>
								<div style={{width:two_third_width}} className='flexCenter'>
									<div style={{width:two_third_width,boxShadow:'0px 0px 30px rgba(0,0,0,0.3)',borderRadius:6}} className='overflow'>
										{bigImageDisplay(two_third_width)}
									</div>
								</div>
							</div>
							: <div className='flexLeft' style={{flexWrap:'wrap'}}>
								<div style={{width:two_third_width}} className='flexLeft'>
									<div style={{width:two_third_width *0.9,boxShadow:'0px 0px 30px rgba(0,0,0,0.3)',borderRadius:6}} className='overflow'>
										{bigImageDisplay(two_third_width *0.9)}
									</div>
								</div>
								<div style={{width:third_width}} className='flexCenter'>
									{elementTextDisplayer(third_width)}
								</div>
							</div>
						}
					</div>
				</div>
			</div>
		)
	}
	return(
		<div className='flexCenter fadeInslower'>
			<div style={{width:default_width,backgroundImage:'linear-gradient(40deg,rgb(14,14,22),rgb(16,22,32))'}} className='borderRadius relative overflow ggl_borderx ggl_box_backgroundx'>
				{default_width < 900
					? <div className=''>
						<div style={{width:default_width}} className='flexRight'>
							<img src={PATTERN} alt='PATTERN' className='fullWidth' style={{width:'80%'}} />
						</div>
						<div className='flexCenter' style={{marginTop:-70}}>
							<div style={{width:default_width-padding*2}}>
								{elementTextDisplayer(third_width)}
							</div>
						</div>
						<div style={{height:padding*1.5+10}}></div>
					</div>
					: <div className='flexCenter'>
						<div style={{width:padding}}></div>
						<div style={{width:third_width}}>
							{elementTextDisplayer(third_width)}
						</div>
						<div style={{width:two_third_width+padding}} className='fullHeight flexRight'>
							<img src={PATTERN} alt='PATTERN' className='fullWidth' style={{width:default_width > 1100 ? '86%' : '110%'}} />
							<div style={{height:70}}></div>
						</div>
					</div>
				}
				{default_width < 900 ? null : <div className='space_height_40'></div> }
				<div className='flexCenter'>
				{default_width < 900
					? <div className='flexLeft' style={{flexWrap:'wrap',width:default_width-(padding*2)}}>
						<div style={{width:default_width-padding*2,marginBottom:20}} className='flexLeft'>{miniElementDisplayer(default_width-padding*2,0)}</div>
						<div style={{width:default_width-padding*2}} className='flexRight'>{miniElementDisplayer(default_width-padding*2,1)}</div>
					</div>
					: <div className='flexLeft' style={{flexWrap:'wrap',width:default_width-(padding*2)}}>
						<div style={{width:default_width/2-padding}} className='flexLeft'>{miniElementDisplayer(default_width/2-padding-(padding*0.3),0)}</div>
						<div style={{width:default_width/2-padding}} className='flexRight'>{miniElementDisplayer(default_width/2-padding-(padding*0.3),1)}</div>
					</div>
				}
				</div>
				<div style={{height:padding}}></div>
			</div>
		</div>
	)
}

export default StandoutDisplay






// I am passionate about technology and coding.

 

// I aspired to hear music I had produced on the radio.

 

// I became obsessed with photo-real 3D rendering and am self-taught.

 

// I spent a year with my family building our home.

 

// I love to create things I see in my head.

 

 

 

 

 

// Self-motivated, self-educated

 

// I love to learn. When I'm pre-occupied by something, I have to learn about it. I have always been motivated to develop a complete understanding of how something I’m interested in works.

 

 

 

 

 

 

 

// +120M Streams Across Platforms

 

// The biggest-selling single I’ve worked on was for the recording artist Trey Songz. In its first week of release, it was the most added song on rhythmic and urban radio.

 

 

 

 

 

 

 

// Where code and music converge

 

// The lines between music and code began to blur, only for my music generation system to emerge from the haze.

 

 

 

 

 

 

 

// Photo-realistic Architectural Rendering

 

// I create architectural renders that are indistinguishable from photos.





