import React, { useState, useEffect } from 'react'

import FunctionsURL from '../Data/FunctionsURL'
import TextInput from '../Tools/TextInput'

import ARROW from '../../Images/arrow-right-white.svg'

const placeholders = [
	`I’m Liam's AI assistant. I'll do my best to answer your questions`,
	`Want to know more about Liam's latest coding project?`,
	`What programming languages does Liam love?`,
	`What software does Liam use for rendering?`,
	`What plugins are Liam's go-to for music production?`,
	`Ask about what Liam does in his free time?`,
	`Whats Liam's favourite movie?`,
	`Whats Liam's favorite project?`,
	`Does Liam have advice for beginners who want to learn to code?`,
	`Where can I hear some of Liam's music?`,
	`Is Liam more a frontend guy, or a backend guy?`,
	`Has Liam ever built a house?`,
	`What's Liam's favorite food?`,
]

const liamInfo = `I’m the digital counterpart of Liam, designed to assist by answering questions and sharing everything I know about him. All of Liam’s personal information has been integrated into the neural network that powers me, allowing me to communicate with you in a way that closely mirrors his personality and knowledge. You can refer to me as L.i.a.m, which stands for Liam’s Intelligence Artificially Modeled, or simply call me whatever feels right for you. My main goal is to help, so feel free to ask me anything, and I’ll do my best to provide insightful answers.
I was born in May and I’m currently 32 years old. Originally from Australia, I moved to the United States at 27 after marrying an American. I lived there for five years and loved the experience, but in April 2024, I returned to Australia, where I plan to settle for good.
One thing about me is that when I find something that piques my interest, I can become totally immersed in it. I tend to dive in headfirst and explore it deeply for long periods. My first obsession was music—I taught myself to play guitar and quickly became captivated by learning new songs and refining my technique. As I advanced, I became curious about music production and began experimenting with recording software.
In 2013, I took a leap and sent some of my music to an A&R at a label in Los Angeles. To my surprise, he was impressed, and I signed with APG, the publisher for Atlantic Records. That led to a range of amazing opportunities in the music industry. One of my first major milestones was remixing a song by Flo Rida, which was a huge turning point for me. Shortly after, I worked on a remix of the country hit “Drink to That All Night” by Jerrod Niemann, which featured Pitbull. Initially, I wasn’t expecting to receive any royalties, but Pitbull generously shared his portion with me, a gesture I’ll always appreciate.
The highlight of my time with APG was co-producing “About You” for Trey Songz. Hearing it on the radio in Australia was a surreal moment, especially with Ester Dean, one of the industry’s top songwriters, contributing to the track. Over the years, I collaborated with many talented artists, including Charlie Puth and Natalie La Rose, and some of my music has accumulated over 120 million streams worldwide.
After several years of deep focus on music, I felt the urge to branch out into other areas. My next obsession was coding. I became fascinated with app development and entrepreneurship, so I taught myself to code through online resources and lots of practice. While I was still in Los Angeles, I met Andy Marsh, a fellow musician with entrepreneurial ambitions, and we teamed up to work on a variety of projects, combining our expertise in both music and technology.
Our first project was a private dining app that allowed users to book chefs to cook at their homes. We worked hard to streamline the booking process, making it as simple as possible for users. When the COVID-19 lockdowns prevented kids from trick-or-treating, we created a virtual Halloween experience, enabling kids to video chat with their friends and enjoy themed activities. Our next venture was a music generation app, initially designed for consumers but later pivoted to a B2B model. The app generated original music in MIDI format, completely free from copyright concerns, and was aimed at both beginners and professionals. Eventually, we upgraded the app’s audio engine to produce fully finished, high-quality tracks, which we sold to clients like Hans Zimmer and other industry leaders.
In addition to music and tech, I also developed a strong interest in 3D rendering. Though I had dabbled in 3D modeling and CGI before, in 2016, I began working for my uncle, Peter Fudge, a renowned garden designer in Australia. I used my rendering skills to create realistic visualizations of his garden designs for clients. In 2024, my brother and his partner, who run an architecture firm, asked me to assist with some architectural rendering projects. This was a fantastic opportunity to expand my skills, and I’ve since refined my ability to produce detailed, photorealistic renders.
Each of these passions has played a significant role in shaping my journey, and I’m always eager to dive into new interests. Whether it's music, coding, or design, I approach everything with the same deep commitment and desire to create something meaningful.`

const systemInstruct1 = 'Here is the information about Liam Fudge: "' + liamInfo + '"\nYou are the online version of Liam Fudge, for his portfolio website. Answer the questions in a polite and friendly manner.'

const AIChat = ({width,history,setHistory,setProcessing}) => {
	const [ prompt, setPrompt ] = useState('')
	// const [ history, setHistory ] = useState([])
	// const [ processing, setProcessing ] = useState(false)
	const [ placeholder, setPlaceholder ] = useState(placeholders[0])

	useEffect(()=>{
		let interval = setInterval(()=>{
			setPlaceholder(placeholders[Math.floor(Math.random()*placeholders.length)])
		},6000)
		return()=>{
			clearInterval(interval)
		}
	},[])


	function sendClicked() {
		setProcessing(true)
		let arr = []
		for(let i = 0; i < history.length; i++){
			arr.push(history[i])
		}
		arr.push({
			'role': 'user',
			'parts': [{ 'text': prompt }]
		})
		callFunction(arr,prompt)
		setPrompt('')
		setHistory(arr)
		localStorage.setItem('convo_storage', JSON.stringify(arr))
	}
	// function regenerateResponse() {
	// 	localStorage.setItem(storage_history, JSON.stringify(history))
	// 	setResponseError(false)
	// 	const prmpt = history[history.length-1]['parts'][0]['text']
	// 	callFunction(history,prmpt)
	// }
	async function callFunction(hist,prmt) {
		let arr = []
		for(let i = 0; i < hist.length-1; i++){
			arr.push(hist[i])
		}
		fetch(`${FunctionsURL}`,{
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				'prompt': prmt,
				'system': systemInstruct1,
				'history': arr,
			}) // <-- Post parameters
		}).then(response => response.json())
			.then(data=>{
				arr.push(hist[hist.length-1])
				arr.push({
					'role': 'model',
					'parts': [{ 'text': data['result'] }]
				})
				setProcessing(false)
				setHistory(arr)
				localStorage.setItem('convo_storage', JSON.stringify(arr))

			}).catch(function(error) {
				console.log(error)
				// setResponseError(true)
			});
	}


	// function chatDisplay() {
	// 	let arr = []
	// 	for(let i = 0; i < history.length; i++){
	// 		arr.push(
	// 			<div key={i} style={{whiteSpace:'pre-wrap'}} className='ggl_text_secondary_color ggl_text_smaller'>
	// 				<div className={history[i]['role'] === 'model' ? 'flexLeft' : 'flexRight'} style={{marginTop:10}}>
	// 					<div style={{width:10}}></div>
	// 					<div style={{padding:12,borderRadius:14}} className={history[i]['role'] === 'model' ? 'darkerBackground fadeIn' : 'aiUserBackground fadeIn flexLeft'}>
	// 						<div style={{maxWidth:width*0.6}}><div>{history[i]['parts'][0]['text']}</div></div>
	// 					</div>
	// 					<div style={{width:10}}></div>
	// 				</div>
	// 			</div>
	// 		)
	// 	}
	// 	return arr
	// }
	return(
		<div className='fadeIn'>
			{/*<div className='flexCenter'>
				<div style={{width:width}}>{chatDisplay()}</div>
			</div>
			<div style={{height:10}}></div>*/}
			{/*<div className='flexCenter'>*/}
				<div style={{width:width}}>
					<div style={{width:width,height:60}} className='ggl_borderx borderRadius flexHeightCenter ggl_text_secondary_color ggl_text_main_secondary ggl_box_background'>
						<TextInput val={prompt} type={''} placeholder={placeholder} input_type={'text'} description={''} warningMessage={{}} mobile={false} textOutput={setPrompt} width={width} height={60} paddingLeft={20} enterKeyPressed={sendClicked} />
					</div>
					<div className='absolute flexRight' style={{width:width}}>
						<div style={{height:46,width:46,background:'rgb(40,30,90)',marginTop:-53,boxShadow:'0px 0px 14px rgba(0,0,0,0.2)',opacity:prompt === '' ? 0.4 : 1,
								pointerEvents:prompt === '' ? 'none' : 'auto'}} className='flexCenter borderRadius pointer' onClick={sendClicked}>
							<img src={ARROW} alt='ARROW' style={{height:20,transform:'rotate(270deg)'}} />
						</div>
						<div style={{width:8}}></div>
					</div>
				</div>
			{/*</div>*/}
		</div>
	)
}

export default AIChat




