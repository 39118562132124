import React from 'react'

import facebook from '../../Images/socials/facebook.svg'
import instagram from '../../Images/socials/instagram.svg'
import youtube from '../../Images/socials/youtube.svg'

const Footer = ({height,navigate}) => {

	return(
		<div style={{background:'black',height:height}} className='flexCenter text_mainx ggl_text_secondary_color'>
			<div style={{width:'40%'}} className='flexCenter'>
				<div>
					{/*<div className='halfOpacity'>Navigation</div>*/}
					<div onClick={()=>{navigate('/')}} className='pointer hoverOpacityDown'>Home</div>
					<div onClick={()=>{navigate('/dev')}} className='pointer hoverOpacityDown'>Developement</div>
					<div onClick={()=>{navigate('/music')}} className='pointer hoverOpacityDown'>Music</div>
					<div onClick={()=>{navigate('/rendering')}} className='pointer hoverOpacityDown'>Architectural rendering</div>
					<div onClick={()=>{navigate('/contact')}} className='pointer hoverOpacityDown'>Contact</div>
					<div onClick={()=>{navigate('/education')}} className='pointer hoverOpacityDown'>Education</div>
				</div>
			</div>
			<div style={{width:'40%'}} className='flexCenter'>
				<div className='pointer flexCenter hoverOpacityUp' style={{height:30,width:30}}>
					<img src={instagram} alt='instagram' style={{height:24}} />
				</div>
				<div className='pointer flexCenter hoverOpacityUp' style={{height:30,width:30}}>
					<img src={facebook} alt='facebook' style={{height:24}} />
				</div>
				<div className='pointer flexCenter hoverOpacityUp' style={{height:30,width:30}}>
					<img src={youtube} alt='youtube' style={{height:24}} />
				</div>
			</div>
			{/*<div style={{width:'25%',border:'1px solid red'}} className='flexCenter'></div>*/}
		</div>
	)
}

export default Footer