import React from 'react'

// import EducationDisplayComponent from '../Education/EducationDisplayComponent'
import EducationData from '../Data/EducationData'
import ProjectCards from '../MiniComponents/ProjectCards'

// const education_list = [
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-6cf1e138-2ed9-43cc-b76a-82c58a69b314.jpg?v=1723931928000',name:'TensorFlow for Deep Learning Bootcamp',year:'2024'},
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-bbcabe5d-5565-4cb5-939d-af4b599a3a92.jpg?v=1633583360000',name:'Natural Language Processing: NPL With Transformers in Python',year:'2021'},
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-3c7f8856-07bf-4f60-a0bf-f8be575ebc2d.jpg?v=1618044059000',name:'Complete Python Developer in 2021: Zero to Mastery',year:'2021'},
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-b4ab6812-98bf-4776-ad57-37e9b16368ab.jpg?v=1619691479000',name:'Complete Machine Learning & Data Science Bootcamp 2021',year:'2021'},
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-LDLX4FW4.jpg?v=1569933538000',name:'Machine Learning in Javascript with Tensorflow.js',year:'2019'},
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-PIMJ8MAU.jpg?v=1533471881000',name:'The Complete Web Developer in 2018: Zero to Mastery',year:'2018'},
// 	{link:'https://udemy-certificate.s3.amazonaws.com/image/UC-5Y8QI8T5.jpg?v=1521070850000',name:'Algorithmic Trading In Forex: Create Your First Forex Robot',year:'2018'},
	
// ]

const Education = ({dimensions,route,default_width}) => {

	// function certificateClicked(e) {
	// 	window.open(e, '_blank', 'noopener,noreferrer')
	// }

	let width = dimensions.width * 0.8
	if(width < 600) width = dimensions.width


	function projectClicked(e) {
		window.open(e['link'], "_blank")
	}

	function educationDisplay(wide) {
		let num_of_cols = 3
		if(wide < 1100) num_of_cols = 2
		if(wide < 600) num_of_cols = 1
		let arr = []
		for(let i = 0; i < EducationData.length; i++){
			arr.push(
				<div key={i} style={{width:wide/num_of_cols,marginBottom:wide/num_of_cols*0.06}} className='flexWidthCenter'>
					<ProjectCards data={EducationData[i]} width={wide/num_of_cols-wide/num_of_cols*0.06} cardClicked={()=>{projectClicked(EducationData[i])}} type={'education'} />
				</div>
			)
		}
		return <div className='flexLeft' style={{flexWrap:'wrap',width:wide}}>{arr}</div>

		// let arr = []
		// for(let i = 0; i < EducationData.length; i++){
		// 	arr.push(
		// 		<div key={i} style={{marginBottom:20}}><EducationDisplayComponent data={EducationData[i]} width={width} /></div>
		// 	)
		// }
		// return arr
	}

	let second_text_width = 720
	if(second_text_width > default_width - 60) second_text_width = default_width - 60

	return(
		<div>
			<div className='flexCenter textCenter radialColorBackground homepage_hero_heightx fadeInslower'>
				<div style={{width:default_width-40}}>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					<div className='ggl_text_hero ggl_text_main_color overflow relative' style={{}}>
						<div className='textAnimateIn absolute textCenter' style={{width:default_width-40}}>My <b className='textGradient'>Education</b></div>
						<div className='noOpacity'>My <b className='textGradient'>Education</b></div>
					</div>
					<div className='space_height_40'></div>
					<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:second_text_width}}>
						<div className='textAnimateIn2 absolute textCenter' style={{width:second_text_width}}>I have completed a lot of self education courses to pursue my interests and expand my knowledge</div>
						<div className='noOpacity'>I have completed a lot of self education courses to pursue my interests and expand my knowledge</div>
					</div></div>

					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
				</div>
			</div>
			<div className='flexCenter radialColorBackground'>
				<div>
					<div className='space_height_60'></div>
					{educationDisplay(default_width)}
					<div className='space_height_60'></div>
				</div>
			</div>
		</div>
	)
}

export default Education








// const DisplayCard = ({data,width,cardClicked}) => {
// 	return(
// 		<div className='borderRadius fadeInslower hoverOpacityDownx pointer relative borderRadius overflow ggl_border ggl_box_background hoverOpacityDown' style={{width:width}} onClick={cardClicked}>
// 			<div style={{padding:20}}>
// 				<div className='overflow borderRadiusx flexCenter' style={{width:width-40-2,height:(width-40)*0.5,boxShadow:'0px 0px 20px rgba(0,0,0,0.2)',borderRadius:6,background:'white'}}>
// 					<img src={data['desktop']} alt='desktop_picture' style={{height:(width-40)*0.5}} />
// 				</div>
// 				<div className='space_height_10'></div>
// 				<div className='ggl_text_smaller ggl_text_main_color boldx uppercasex' style={{textShadow:'0px 0px 20px black'}}>{data['name']}</div>
// 				<div className='ggl_text_smaller ggl_text_secondary_color'>{data['subheading']}</div>
// 			</div>
// 		</div>
// 	)
// }



