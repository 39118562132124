import about_you from '../../Images/album_arts_tinified/about_you.jpg'
import trey from '../../Images/album_arts_tinified/treysongz2.jpg'
import drink_to_that_album from '../../Images/album_arts_tinified/drink_to_that_album.jpeg'
import drink_to_that_video from '../../Images/album_arts_tinified/drink_to_that_video.jpg'
import stay_album from '../../Images/album_arts_tinified/stay_album.jpg'
import stay_video from '../../Images/album_arts_tinified/stay_video.webp'
import how_i_feel_album from '../../Images/album_arts_tinified/how_i_feel_album.jpg'
import how_i_feel_video from '../../Images/album_arts_tinified/florida.jpg'
import justice_crew from '../../Images/album_arts_tinified/justice_crew.jpeg'
import justice_crew_2 from '../../Images/album_arts_tinified/justice_crew_2.jpg'
import apg_logo from '../../Images/album_arts_tinified/apg_logo.jpg'
import apg_studio from '../../Images/album_arts_tinified/apg_studio.jpg'
import rodeo_wine_album from '../../Images/album_arts_tinified/rodeo_wine_album.jpg'
import rodeo_wine_video from '../../Images/album_arts_tinified/rodeo_wine_video2.jpg'
import lions_album from '../../Images/album_arts_tinified/lions_album.jpg'
import lions_video from '../../Images/album_arts_tinified/lions_video.png'
import fab_album from '../../Images/album_arts_tinified/fab_album.jpg'
import fab_video from '../../Images/album_arts_tinified/fab_video.png'
import bleeding_fingers_album from '../../Images/album_arts_tinified/bleeding_fingers_album.jpg'
import bleeding_fingers_video from '../../Images/album_arts_tinified/bleeding_fingers_video.jpg'
import bill_murray from '../../Images/album_arts_tinified/bill_murray.png'
import patronus from '../../Images/album_arts_tinified/patronus.png'

import trey_songz_radio from '../../Images/trey_songz_radio.JPG'

const MusicProjects = {
	'apg': {
		'song_name': `Signed as Producer`,
		'name': `Artist Publishing Group`,
		'mini': `Signed as Producer and Songwriter`,
		'artist': 'Artist Publishing Group',
		'album_art': apg_logo,
		'background': apg_studio,
		'desktop': apg_studio,
		'link': 'https://www.artistpg.com/',
		'role': 'Producer, Songwriter',
		'button': 'View site',
	},
	'bleeding': {
		'song_name': `Music accepted by Hans Zimmer`,
		'name': `Bleeding Fingers`,
		'mini': `Music accepted by Hans Zimmer`,
		'artist': 'Bleeding Fingers',
		'album_art': bleeding_fingers_album,
		'background': bleeding_fingers_video,
		'desktop': bleeding_fingers_video,
		'link': 'https://bleedingfingersmusic.com/',
		'role': 'Producer',
		'button': 'View site',
	},
	'aboutyou': {
		'song_name': 'About You',
		'artist': 'Trey Songz',
		'album_art': about_you,
		'background': trey,
		'desktop': trey_songz_radio,
		'link': 'https://www.youtube.com/watch?v=6EFeoYHx88Y',
		'role': 'Producer',
		'button': 'Hear song',
	},
	'drinktothat': {
		'song_name': 'Drink to That All Night (Remix)',
		'artist': 'Jerrod Niemann feat. Pitbull',
		'album_art': drink_to_that_album,
		'background': drink_to_that_video,
		'link': 'https://www.youtube.com/watch?v=mXx3DVppCwg',
		'role': 'Producer',
		'button': 'Hear song',
	},
	'stay': {
		'song_name': 'Stay (The Kemist Remix)',
		'artist': 'Zedd feat Alessia Cara',
		'album_art': stay_album,
		'background': stay_video,
		'link': 'https://www.youtube.com/watch?v=mXHy4xfvq1s',
		'role': 'Producer',
		'button': 'Hear song',
	},
	'howifeel': {
		'song_name': 'How I Feel (Twenty1 Remix)',
		'artist': 'Flo Rida',
		'album_art': how_i_feel_album,
		'background': how_i_feel_video,
		'link': 'https://www.youtube.com/watch?v=MToNIKm8nM8',
		'role': 'Producer',
		'button': 'Hear song',
	},
	'rodeo_wine': {
		'song_name': `Rodeo Wine`,
		'artist': 'Nyanda',
		'album_art': rodeo_wine_album,
		'background': rodeo_wine_video,
		'link': 'https://www.youtube.com/watch?v=0qHwr3RWU8w',
		'role': 'Producer',
		'button': 'Hear song',
	},
	'fab': {
		'song_name': `FAB. (The Kemist Remix)`,
		'artist': 'JoJo feat. Remy Ma',
		'album_art': fab_album,
		'background': fab_video,
		'link': 'https://www.youtube.com/watch?v=Fn5j0Ahnq_I',
		'role': 'Producer',
		'button': 'Hear song',
	},
	'justicecrew': {
		'song_name': `Life's a Party`,
		'artist': 'Justice Crew',
		'album_art': justice_crew,
		'background': justice_crew_2,
		'link': 'https://www.youtube.com/watch?v=LmICk5-Nsi8',
		'role': 'Producer, Songwriter',
		'button': 'Hear song',
	},
	'lions': {
		'song_name': `Lions The Kemist Remix`,
		'artist': 'Skip Marley',
		'album_art': lions_album,
		'background': lions_video,
		'link': 'https://www.youtube.com/watch?v=yzsWw5_CCtk',
		'role': 'Producer',
		'button': 'Hear song',
	},
	'bill_murray': {
		'song_name': `Bill Murray`,
		'artist': 'Andy Marsh',
		'album_art': bill_murray,
		'background': bill_murray,
		'link': 'https://soundcloud.com/andymarshmusic/varun-ave-efx-bill-murray-andy-marsh-twenty1-remix',
		'role': 'Producer',
		'button': 'Hear song',
	},
	'patronus': {
		'song_name': `Protective (feat. Brooke)`,
		'artist': 'Patronus',
		'album_art': patronus,
		'background': patronus,
		'link': 'https://soundcloud.com/patronus_music/protective-feat-brooke',
		'role': 'Producer, Songwriter',
		'button': 'Hear song',
	},
}

export default MusicProjects