import a_bed2 from '../../Images/renders_tinified/a_bed2.png'
import a_bed3 from '../../Images/renders_tinified/a_bed3.png'
import a_cm1 from '../../Images/renders_tinified/a_cm1.png'
import a_cm2 from '../../Images/renders_tinified/a_cm2.png'
import a_cm3 from '../../Images/renders_tinified/a_cm3.png'
import a_concrete_house from '../../Images/renders_tinified/a_concrete_house.png'
import a_cosey_bed1 from '../../Images/renders_tinified/a_cosey_bed1.png'
import a_cosey_bed2 from '../../Images/renders_tinified/a_cosey_bed2.png'
import a_hamtpons from '../../Images/renders_tinified/a_hamtpons.png'
import a_lib1 from '../../Images/renders_tinified/a_lib1.png'
import a_lib2 from '../../Images/renders_tinified/a_lib2.png'
import a_modern_kitchen from '../../Images/renders_tinified/a_modern_kitchen.png'
import a_pen_house from '../../Images/renders_tinified/a_pen_house.png'
import a_tina from '../../Images/renders_tinified/a_tina.png'
import drafting1 from '../../Images/renders_tinified/drafting1_2.jpg'
import drafting2 from '../../Images/renders_tinified/drafting2_2.jpg'
import drafting3 from '../../Images/renders_tinified/drafting3.png'
import drafting4 from '../../Images/renders_tinified/drafting4_2.jpg'
import sydney_kitchen from '../../Images/renders_tinified/sydney_kitchen.png'
import sydney_kitchen2 from '../../Images/renders_tinified/sydney_kitchen2.png'
import dark_kitchen from '../../Images/renders_tinified/dark_kitchen.png'
import boyce_mitta from '../../Images/renders_tinified/boyce_mitta.png'
import da_col_mitta from '../../Images/renders_tinified/da_col_mitta.png'
import kangaloon_barn from '../../Images/renders_tinified/kangaloon_barn.png'
import exterior_render_1 from '../../Images/renders_tinified/exterior_render_1.png'
import exterior_render_2 from '../../Images/renders_tinified/exterior_render_2.png'
import dark_kitchen_2 from '../../Images/renders_tinified/dark_kitchen_2.png'
import exterior_render_model from '../../Images/renders_tinified/exterior_render_model.png'
import sydney_kitchen_model from '../../Images/renders_tinified/sydney_kitchen_model.png'
import dark_kitchen_model from '../../Images/renders_tinified/dark_kitchen_model.png'
import section_render from '../../Images/renders_tinified/section_render2.png'
import modern_scottland from '../../Images/renders_tinified/modern_scottland.png'
import modern_suburbs from '../../Images/renders_tinified/modern_suburbs.png'
import beach_house_1 from '../../Images/renders_tinified/beach_house_1.png'
import beach_house_2 from '../../Images/renders_tinified/beach_house_2.png'
import warm_interior from '../../Images/renders_tinified/warm_interior.png'
import bathroom1 from '../../Images/renders_tinified/bathroom1.png'
import boyce2 from '../../Images/renders_tinified/boyce2.png'

import a_bed2_thumb from '../../Images/renders_tinified/a_bed2_thumb.png'
import a_bed3_thumb from '../../Images/renders_tinified/a_bed3_thumb.png'
import a_cm1_thumb from '../../Images/renders_tinified/a_cm1_thumb.png'
import a_cm2_thumb from '../../Images/renders_tinified/a_cm2_thumb.png'
import a_cm3_thumb from '../../Images/renders_tinified/a_cm3_thumb.png'
import a_concrete_house_thumb from '../../Images/renders_tinified/a_concrete_house_thumb.png'
import a_cosey_bed1_thumb from '../../Images/renders_tinified/a_cosey_bed1_thumb.png'
import a_cosey_bed2_thumb from '../../Images/renders_tinified/a_cosey_bed2_thumb.png'
import a_hamtpons_thumb from '../../Images/renders_tinified/a_hamtpons_thumb.png'
import a_lib1_thumb from '../../Images/renders_tinified/a_lib1_thumb.png'
import a_lib2_thumb from '../../Images/renders_tinified/a_lib2_thumb.png'
import a_modern_kitchen_thumb from '../../Images/renders_tinified/a_modern_kitchen_thumb.png'
import a_pen_house_thumb from '../../Images/renders_tinified/a_pen_house_thumb.png'
import a_tina_thumb from '../../Images/renders_tinified/a_tina_thumb.png'
import drafting1_thumb from '../../Images/renders_tinified/drafting1_2_thumb.jpg'
import drafting2_thumb from '../../Images/renders_tinified/drafting2_2_thumb.jpg'
import drafting3_thumb from '../../Images/renders_tinified/drafting3_thumb.png'
import drafting4_thumb from '../../Images/renders_tinified/drafting4_2_thumb.jpg'
import sydney_kitchen_thumb from '../../Images/renders_tinified/sydney_kitchen_thumb.png'
import sydney_kitchen2_thumb from '../../Images/renders_tinified/sydney_kitchen2_thumb.png'
import dark_kitchen_thumb from '../../Images/renders_tinified/dark_kitchen_thumb.png'
import boyce_mitta_thumb from '../../Images/renders_tinified/boyce_mitta_thumb.png'
import da_col_mitta_thumb from '../../Images/renders_tinified/da_col_mitta_thumb.png'
import kangaloon_barn_thumb from '../../Images/renders_tinified/kangaloon_barn_thumb.png'
import exterior_render_1_thumb from '../../Images/renders_tinified/exterior_render_1_thumb.png'
import exterior_render_2_thumb from '../../Images/renders_tinified/exterior_render_2_thumb.png'
import dark_kitchen_2_thumb from '../../Images/renders_tinified/dark_kitchen_2_thumb.png'
// import exterior_render_model_thumb from '../../Images/renders_tinified/exterior_render_model_thumb.png'
// import sydney_kitchen_model_thumb from '../../Images/renders_tinified/sydney_kitchen_model_thumb.png'
// import dark_kitchen_model_thumb from '../../Images/renders_tinified/dark_kitchen_model_thumb.png'
import section_render_thumb from '../../Images/renders_tinified/section_render2_thumb.png'
import modern_scottland_thumb from '../../Images/renders_tinified/modern_scottland_thumb.png'
import modern_suburbs_thumb from '../../Images/renders_tinified/modern_suburbs_thumb.png'
import beach_house_1_thumb from '../../Images/renders_tinified/beach_house_1_thumb.png'
import beach_house_2_thumb from '../../Images/renders_tinified/beach_house_2_thumb.png'
import warm_interior_thumb from '../../Images/renders_tinified/warm_interior_thumb.png'
import bathroom1_thumb from '../../Images/renders_tinified/bathroom1_thumb.png'
import boyce2_thumb from '../../Images/renders_tinified/boyce2_thumb.png'

import video1 from '../../Images/renders_tinified/arch5small.mp4'

const RenderProject = {

	'modern_scottland': {
		'key': 'modern_scottland',
		'src': modern_scottland,
		'thumb': modern_scottland_thumb,
		'type': 'wide',
		'model': exterior_render_model,
		'video': video1,
		'heading': 'Exterior rendering',
		'subheading': 'Stunning, photo-real exterior architectural rendering',
	},

	'beach_house_2': {
		'key': 'beach_house_2',
		'src': beach_house_2,
		'thumb': beach_house_2_thumb,
		'type': 'square',
		'model': beach_house_2,
		'video': video1,
	},
	

	'warm_interior': {
		'key': 'warm_interior',
		'src': warm_interior,
		'thumb': warm_interior_thumb,
		'type': 'wide',
		'model': warm_interior,
		'video': video1,
	},
	'modern_suburbs': {
		'key': 'modern_suburbs',
		'src': modern_suburbs,
		'thumb': modern_suburbs_thumb,
		'type': 'wide',
		'model': exterior_render_model,
		'video': video1,
	},

	'boyce2': {
		'key': 'boyce2',
		'src': boyce2,
		'thumb': boyce2_thumb,
		'type': 'wide',
		'model': exterior_render_model,
		'video': video1,
	},

	'exterior_render_1': {
		'key': 'exterior_render_1',
		'src': exterior_render_1,
		'thumb': exterior_render_1_thumb,
		'type': 'wide',
		'model': exterior_render_model,
		'video': video1,
	},

	'beach_house_1': {
		'key': 'beach_house_1',
		'src': beach_house_1,
		'thumb': beach_house_1_thumb,
		'type': 'wide',
		'model': beach_house_1,
		'video': video1,
	},

	'dark_kitchen': {
		'key': 'dark_kitchen',
		'src': dark_kitchen,
		'thumb': dark_kitchen_thumb,
		'type': 'wide',
		'model': dark_kitchen_model,
		'video': video1,
		'heading': 'Interior rendering',
		'subheading': 'High quality finishes on interior architectural rendering',
	},
	'bathroom1': {
		'key': 'bathroom1',
		'src': bathroom1,
		'thumb': bathroom1_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'sydney_kitchen2': {
		'key': 'sydney_kitchen2',
		'src': sydney_kitchen2,
		'thumb': sydney_kitchen2_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'exterior_render_2': {
		'key': 'exterior_render_2',
		'src': exterior_render_2,
		'thumb': exterior_render_2_thumb,
		'type': 'wide',
		'model': exterior_render_model,
		'video': video1,
	},
	'sydney_kitchen': {
		'key': 'sydney_kitchen',
		'src': sydney_kitchen,
		'thumb': sydney_kitchen_thumb,
		'type': 'wide',
		'model': sydney_kitchen_model,
		'video': video1,
	},
	'a_pen_house': {
		'key': 'a_pen_house',
		'src': a_pen_house,
		'thumb': a_pen_house_thumb,
		'type': 'high',
		'model': exterior_render_model,
		'video': video1,
	},

	'dark_kitchen_2': {
		'key': 'dark_kitchen_2',
		'src': dark_kitchen_2,
		'thumb': dark_kitchen_2_thumb,
		'type': 'wide',
		'model': exterior_render_model,
		'video': video1,
	},
	
	

	

	'boyce_mitta': {
		'key': 'boyce_mitta',
		'src': boyce_mitta,
		'thumb': boyce_mitta_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'da_col_mitta': {
		'key': 'da_col_mitta',
		'src': da_col_mitta,
		'thumb': da_col_mitta_thumb,
		'type': 'wide',
		'model': exterior_render_model,
		'video': video1,
	},

	'kangaloon_barn': {
		'key': 'kangaloon_barn',
		'src': kangaloon_barn,
		'thumb': kangaloon_barn_thumb,
		'type': 'wide',
		'model': exterior_render_model,
		'video': video1,
	},
	

	
	'a_modern_kitchen': {
		'key': 'a_modern_kitchen',
		'src': a_modern_kitchen,
		'thumb': a_modern_kitchen_thumb,
		'type': 'wide',
		'model': exterior_render_model,
		'video': video1,
	},
	'a_hamtpons': {
		'key': 'a_hamtpons',
		'src': a_hamtpons,
		'thumb': a_hamtpons_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'a_bed2': {
		'key': 'a_bed2',
		'src': a_bed2,
		'thumb': a_bed2_thumb,
		'type': 'wide',
		'model': exterior_render_model,
		'video': video1,
	},
	'a_cosey_bed1': {
		'key': 'a_cosey_bed1',
		'src': a_cosey_bed1,
		'thumb': a_cosey_bed1_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'drafting3': {
		'key': 'drafting3',
		'src': drafting3,
		'thumb': drafting3_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'a_lib2': {
		'key': 'a_lib2',
		'src': a_lib2,
		'thumb': a_lib2_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'a_cm1': {
		'key': 'a_cm1',
		'src': a_cm1,
		'thumb': a_cm1_thumb,
		'type': 'high',
		'model': exterior_render_model,
		'video': video1,
	},
	'a_cosey_bed2': {
		'key': 'a_cosey_bed2',
		'src': a_cosey_bed2,
		'thumb': a_cosey_bed2_thumb,
		'type': 'high',
		'model': exterior_render_model,
		'video': video1,
	},
	'a_bed3': {
		'key': 'a_bed3',
		'src': a_bed3,
		'thumb': a_bed3_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'drafting2': {
		'key': 'drafting2',
		'src': drafting2,
		'thumb': drafting2_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'a_tina': {
		'key': 'a_tina',
		'src': a_tina,
		'thumb': a_tina_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'a_cm2': {
		'key': 'a_cm2',
		'src': a_cm2,
		'thumb': a_cm2_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'a_lib1': {
		'key': 'a_lib1',
		'src': a_lib1,
		'thumb': a_lib1_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'drafting4': {
		'key': 'drafting4',
		'src': drafting4,
		'thumb': drafting4_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'a_cm3': {
		'key': 'a_cm3',
		'src': a_cm3,
		'thumb': a_cm3_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},
	'a_concrete_house': {
		'key': 'a_concrete_house',
		'src': a_concrete_house,
		'thumb': a_concrete_house_thumb,
		'type': 'wide',
		'model': exterior_render_model,
		'video': video1,
	},
	'drafting1': {
		'key': 'drafting1',
		'src': drafting1,
		'thumb': drafting1_thumb,
		'type': 'square',
		'model': exterior_render_model,
		'video': video1,
	},

	'section_render': {
		'key': 'section_render',
		'src': section_render,
		'thumb': section_render_thumb,
		'type': 'wide',
		'model': exterior_render_model,
		'video': video1,
	},
	
}

export default RenderProject