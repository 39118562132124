import React from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

import Portfolio from '../Data/Portfolio'
import MusicProjects from '../Data/MusicProjects'
// import RenderProject from '../Data/RenderProject'

import ProjectDisplayContainer from '../Portfolio/ProjectDisplayContainer'
import MusicDisplayContainer from '../Music/MusicDisplayContainer'
// import RenderingDisplay from '../Rendering/RenderingDisplay'
import ProjectFullDisplay from '../Portfolio/ProjectFullDisplay'
// import SliderSelector from '../Skills/SliderSelector'
import ProgramCircleDisplayer from '../Portfolio/ProgramCircleDisplayer'
import ProjectBanner from '../Banner/ProjectBanner'
import HomePageSectionRender from '../Rendering/HomePageSectionRender'

// import banner from '../../Images/banners/banner1.jpg'
import chevron from '../../Images/chevron-left.svg'

const num_to_key = ['</> Dev','Music production','3D Rendering']
// const colors = [[20,60,180],[240,70,90],[90,20,130]]
// const colors = [[70,40,210],[240,70,90],[90,20,130]]

const Projects = ({dimensions,route, projectSlider,sliderClick,default_width}) => {
	// const [ type, setType ] = useState({prev:0,current:0,count:11})  // skill passion experience

	function changeType(e) {
		if(e !== projectSlider['current']){
			let count = 1
			sliderClick({prev:projectSlider['current'],current:e,count:count})
			let interval = setInterval(()=>{
				count+=1
				sliderClick({prev:projectSlider['current'],current:e,count:count})
				if(count > 10) clearInterval(interval)
			},20)
		}
	}

	
	const navigate = useNavigate()

	function projectClicked(e) {
		navigate('/projects/'+e)
	}
	// function navigateToProjects() {
	// 	navigate('/projects')
	// }
	function nextProject(e) {
		let lastKey = Object.keys(Portfolio)[ Object.keys(Portfolio).length-1 ]
		for(const [key,] of Object.entries(Portfolio)){
			if(lastKey === e){
				projectClicked(key)
				break
				// lastKey = key
			} else {
				lastKey = key
			}
		}
		// projectClicked(lastKey)
	}
	function backProject(e) {
		let lastKey = Object.keys(Portfolio)[ Object.keys(Portfolio).length-1 ]
		for(const [key,] of Object.entries(Portfolio)){
			if(key === e){
				break
			} else {
				lastKey = key
			}
		}
		projectClicked(lastKey)
	}

	function projectDisplay(hide,len,wide) {
		let arr = []
		let count = 0
		for(const [key, val] of Object.entries(Portfolio)){
			if(key !== hide && count < len){
				arr.push(
					<div key={key}>
						<div onClick={()=>{projectClicked(key)}}><ProjectDisplayContainer data={val} width={wide} /></div>
						<div className='space_height_20'></div>
					</div>
				)
				count+=1
			}
		}
		return arr
	}
	function musicDisplay(hide,len,wide) {
		let arr = []
		let count = 0
		for(const [key, val] of Object.entries(MusicProjects)){
			if(!hide.includes(key)  && count < len){
				arr.push(
					<div key={key}>
						<MusicDisplayContainer data={val} width={wide} />
						<div className='space_height_20'></div>
					</div>
				)
				count+=1
			}
		}
		return arr
	}

	// let width = dimensions.width * 0.8
	// if(width < 600) width = dimensions.width
	const width = default_width

	if(route === 'projects'){
		return(
			<div className='ggl_text_main_secondary'>
				<div className='absolutex'><ProjectBanner width={dimensions.width} height={dimensions.width*0.44} type={projectSlider} changeType={changeType} /></div>
				{/*<div style={{width:dimensions.width,height:dimensions.width*0.44}} className='flexCenter absolute'>
					<img src={banner} alt='banner' className='fullHeight' />
				</div>*/}
				{/*<div style={{width:dimensions.width,height:dimensions.width*0.44}} className='flexCenter relative'>
					<SliderSelector type={projectSlider} changeType={changeType} num_to_key={num_to_key} colors={colors} />
				</div>*/}
				<div className='heightSep'></div>
				<div className='heightSep'></div>
				<div className='heightSep'></div>
				{/*<div className='text_heading textCenter bold paragraphMarginBottom'>Portfolio</div>*/}
				{/*<div className='flexCenter paragraphMarginBottom'><SliderSelector type={projectSlider} changeType={changeType} num_to_key={num_to_key} colors={colors} /></div>*/}
				<div className='flexCenter'>
					<div style={{width:width}}>
						<div className='ggl_text_main_secondary paragraphMarginBottom uppercase ggl_text_main_color bold'>{num_to_key[projectSlider['current']]}</div>
						<div className='ggl_text_smaller paragraphMarginBottom ggl_text_secondary_color' >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
						<div className='heightSep'></div>
						<div className='heightSep'></div>

						{projectSlider['count'] > 10
							? <ProgramCircleDisplayer width={width} dimensions={{width:500}} type={projectSlider} />
							: 'loading'
						}
					</div>
				</div>

				<div className='heightSep'></div>
				<div className='heightSep'></div>
				<div className='heightSep'></div>
				<div className='flexCenter'><div>
					{projectSlider['count'] > 10
						? projectSlider['current'] === 0
							? projectDisplay('',Object.keys(Portfolio).length,width)
							: projectSlider['current'] === 1
								? musicDisplay(['bill_murray','patronus'],500,width)
								: <div style={{width:width}}><HomePageSectionRender width={width} fullDisplay={true} /></div>
						: 'loading'
					}
				</div></div>
				<div className='heightSep'></div>
			</div>
		)
	}
	let rte = route.replace('projects/','')
	return(
		<div className='ggl_text_main_secondary'>
			{/*<div onClick={navigateToProjects} className='prevNextArrowCircle pointer flexCenter absolute' style={{width:130,height:32,zIndex:1}}>
				<img src={chevron} alt='chevron' style={{width:26}} />
				<div className='bold text_main uppercasex blackColor'>All projects</div>
				<div style={{width:20}}></div>
			</div>*/}

			<div className='flexCenter absolute pointerNone fadeInslow' style={{width:dimensions.width,height:dimensions.width*0.5,zIndex:1}}>
				<div className='halfWidth fullHeight flexCenter flexLeft'>
					<div onClick={()=>{backProject(rte)}} className='prevNextArrowCircle pointer flexCenter pointerAuto' style={{backgroundImage:'linear-gradient(rgba(0,0,0,0.3),rgba(255,255,255,1))',border:'1px solid rgb(120,120,120)'}}>
						<img src={chevron} alt='chevron' style={{width:30}} />
					</div>
				</div>
				<div className='halfWidth fullHeight flexCenter flexRight'>
					<div onClick={()=>{nextProject(rte)}} className='prevNextArrowCircle pointer flexCenter pointerAuto' style={{backgroundImage:'linear-gradient(rgba(0,0,0,0.3),rgba(255,255,255,1)',border:'1px solid rgb(120,120,120)'}}>
						<img src={chevron} alt='chevron' style={{width:30,transform:'rotate(180deg)'}} />
					</div>
				</div>
			</div>
			<div className='pointerNone'><ProjectDisplayContainer data={Portfolio[rte]} width={dimensions.width} /></div>

			<div className='flexCenter'><ProjectFullDisplay key_={rte} width={width} /></div>

			<div className='space_height_60'></div>
			<div className='space_height_20'></div>
		</div>
	)
}

// export default Projects

const mapStateToProps = (state) => ({
	projectSlider: state.projectSlider.projectSlider
})
const mapDispatchToProps = (dispatch) => ({
	sliderClick: (e) => dispatch({type: 'PROJECT_SLIDER', payload: e }),
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects)



