import React from 'react'

import Portfolio from '../Data/Portfolio'
import StandoutDisplay from '../MiniComponents/StandoutDisplay'
import ProjectCards from '../MiniComponents/ProjectCards'

const DevRoute = ({dimensions,default_width,navigate}) => {


	function projectClicked(e) {
		navigate('/projects/'+e)
	}
	function goToLink(e) {
		window.open(e, '_blank', 'noopener,noreferrer')
	}



	function projectDisplay(hide,len,wide) {
		let num_of_cols = 3
		if(wide < 1100) num_of_cols = 2
		if(wide < 600) num_of_cols = 1
		let arr = []
		let count = 0
		for(const [key, val] of Object.entries(Portfolio)){
			if(key !== hide && count < len){
				arr.push(
					<div key={key} style={{width:wide/num_of_cols,marginBottom:wide/num_of_cols*0.06}} className='flexWidthCenter'>
						<ProjectCards data={val} width={wide/num_of_cols-wide/num_of_cols*0.06} cardClicked={()=>{projectClicked(key)}} type={'dev'} goToLink={goToLink} key_={key} />
					</div>
				)
				count+=1
			}
		}
		return <div className='flexLeft' style={{flexWrap:'wrap',width:wide}}>{arr}</div>
	}

	let second_text_width = 720
	if(second_text_width > default_width - 60) second_text_width = default_width - 60
	// let padding = 80
	// if(default_width < 600){
	// 	padding = 10
	// } else if(default_width < 600){
	// 	padding = 20
	// } else if(default_width < 800){
	// 	padding = 30
	// } else if(default_width < 1100){
	// 	padding = 40
	// } else if(default_width < 1200){
	// 	padding = 60
	// }

	return(
		<div>

			<div className='flexCenter textCenter radialColorBackground homepage_hero_heightx fadeInslower'>
				<div style={{width:default_width-40}}>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					<div className='ggl_text_hero ggl_text_main_color overflow relative' style={{}}>
						<div className='textAnimateIn absolute textCenter' style={{width:default_width-40}}>Creatively <b className='textGradient'>Developed</b> Tech</div>
						<div className='noOpacity'>Creatively <b className='textGradient'>Developed</b> Tech</div>
					</div>
					<div className='space_height_40'></div>
					<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:second_text_width}}>
						<div className='textAnimateIn2 absolute textCenter' style={{width:second_text_width}}>Hi, I’m Liam. My passion lies in creativity applying technology across coding, music, and architectural rendering</div>
						<div className='noOpacity'>Hi, I’m Liam. My passion lies in creativity applying technology across coding, music, and architectural rendering</div>
					</div></div>

					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
				</div>
			</div>


			{/*<VisualDisplayExample />*/}

			<StandoutDisplay default_width={default_width} navigate={navigate} dimensions={dimensions} type={'code_and_music'} />
			<div className='space_height_60'></div>
			<StandoutDisplay default_width={default_width} navigate={navigate} dimensions={dimensions} type={'social_network'} />
			<div className='space_height_60'></div>
			<StandoutDisplay default_width={default_width} navigate={navigate} dimensions={dimensions} type={'self_education'} />

			<div className='space_height_60'></div>
			<div className='space_height_60'></div>

			<div className='radialColorBackground'>
				<div className='flexCenter' style={{width:dimensions.width}}>
					<div style={{width:default_width}}>
						<div className='ggl_text_heading ggl_text_main_color overflow relative fullWidth'>
							<div className='textAnimateIn absolute textCenter boldz' style={{width:default_width}}>All <b className='textGradientLighter noBold'>Projects</b></div>
							<div className='noOpacity'>All<b className='textGradientLighter noBold'>Projects</b></div>
						</div>
						<div className='ggl_text_secondary_color overflow relative' style={{}}>
							<div className='textAnimateIn2 absolute textCenter' style={{width:default_width}}>Here are some of the projects I have developed</div>
							<div className='noOpacity'>Here are some of the projects I have developed</div>
						</div>
					</div>
				</div>
				<div className='space_height_40'></div>
				<div className='flexCenter'>
					<div>{projectDisplay('',Object.keys(Portfolio).length,default_width)}</div>
				</div>
				<div className='space_height_40'></div>

			</div>

		</div>
	)
}

export default DevRoute







// const DisplayCard = ({data,width,cardClicked}) => {
// 	return(
// 		<div className='borderRadius fadeIn hoverOpacityDownx pointer relative borderRadius overflow ggl_border ggl_box_background hoverOpacityDown' style={{width:width}} onClick={cardClicked}>
// 			<div style={{padding:20}}>
// 				<div className='overflow borderRadiusx ggl_borderx' style={{width:width-40-2,height:(width-40)*0.5,boxShadow:'0px 0px 20px rgba(0,0,0,0.2)',borderRadius:6}}>
// 					<img src={data['desktop']} alt='desktop_picture' style={{width:width-40-2}} />
// 				</div>
// 				<div className='space_height_10'></div>
// 				<div className='ggl_text_smaller ggl_text_main_color boldx uppercasex' style={{textShadow:'0px 0px 20px black'}}>{data['heading']}</div>
// 				<div className='ggl_text_smaller ggl_text_secondary_color'>{data['subheading']}</div>
// 			</div>
// 		</div>
// 	)
// }












// const VisualDisplayExample = () => {
// 	const [ lines, setLines ] = React.useState([
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 		[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
// 		[],
// 	])

// 	React.useEffect(()=>{
// 		console.log(Math.floor(8/3))
// 	},[])
// 	const unit_high = 80
// 	const unit_wide = 0.866


// 	function lineClicked(row,col){
// 		let arr = []
// 		for(let i = 0; i < lines.length; i++){
// 			if(i === row){
// 				let arr2 = []
// 				for(let j = 0; j < lines[i].length; j++){
// 					if(j === col){
// 						if(lines[i][j] === 0){
// 							arr2.push(1)
// 						} else if(lines[i][j] === 1){
// 							arr2.push(2)
// 						} else {
// 							arr2.push(0)
// 						}
// 					} else {
// 						arr2.push(lines[i][j])
// 					}
// 				}
// 				arr.push(arr2)
// 			} else {
// 				arr.push(lines[i])
// 			}
// 		}
// 		setLines(arr)
// 		// if(lines[row][col] === 0){
// 		// 	lines[row][col] = 1
// 		// }
// 		console.log('howdyyyhowdyyy ')
// 	}
	


// 	function lineLoop(height) {
// 		let arr = []
// 		for(let i = 0; i < lines[height].length; i++){
// 			const floorDiv = Math.floor(i/4)
// 			let angle = 'rotate(-30deg)'
// 			let margLeft = (floorDiv*unit_high)*0.868
// 			let margTop = (floorDiv*unit_high)*0.5
// 			if(i%4 === 1){
// 				angle = 'rotate(30deg)'
// 				margLeft = (floorDiv*unit_high)*0.868
// 				margTop = (floorDiv*unit_high)*0.5 + unit_high*0.5
// 			} else if(i%4 === 2){
// 				angle = 'rotate(90deg)'
// 				margLeft = -(unit_high*unit_wide)*0.496 + (floorDiv*unit_high*unit_wide)
// 				margTop = (floorDiv*unit_high*0.5) + unit_high*0.75
// 			} else if(i%4 === 3){
// 				angle = 'rotate(30deg)'
// 				margLeft = (floorDiv*unit_high)*0.868
// 				margTop = (floorDiv*unit_high*0.5) + unit_high*1.5
// 			}

// 			let border = '2px solid white'
// 			if(lines[height][i] === 0){
// 				border = '2px dotted white'
// 			} else if(lines[height][i] === 2){
// 				border = '6px solid white'
// 			}
// 			arr.push(
// 				<div key={i} style={{width:unit_high,borderBottom:border,transform:angle,marginTop:margTop+(height*unit_high),marginLeft:margLeft}} className='absolute' onClick={()=>{lineClicked(height,i)}}></div>
// 			)
// 		}
// 		return arr
// 	}
// 	return(
// 		<div style={{height:3600,width:'99%',border:'1px solid red'}}>
// 			{lineLoop(0)}
// 			{lineLoop(2)}
// 			{lineLoop(4)}
// 			{lineLoop(6)}
// 			{lineLoop(8)}
// 			{lineLoop(10)}
// 			{lineLoop(12)}
// 			{lineLoop(14)}
// 			{lineLoop(16)}
// 			{lineLoop(18)}
// 			{lineLoop(20)}

// 			{/*<div style={{height:unit_high,borderRight:border,marginTop:unit_high*2-unit_high*0.25,marginLeft:unit_high*unit_wide+(unit_high*unit_wide*0.076)}} className='absolute'></div>

// 			<div style={{width:unit_high,borderBottom:border,transform:'rotate(-30deg)',marginTop:unit_high,marginLeft:0}} className='absolute'></div>
// 			<div style={{width:unit_high,borderBottom:border,transform:'rotate(30deg)',marginTop:unit_high*1.5,marginLeft:0}} className='absolute'></div>

// 			<div style={{width:unit_high,borderBottom:border,transform:'rotate(30deg)',marginTop:unit_high,marginLeft:unit_high*unit_wide}} className='absolute'></div>
// 			<div style={{width:unit_high,borderBottom:border,transform:'rotate(-30deg)',marginTop:unit_high*1.5,marginLeft:unit_high*unit_wide}} className='absolute'></div>*/}
// 		</div>
// 	)
// }

















