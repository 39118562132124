import React, { useState, useEffect } from 'react'
import ClipLoader from 'react-spinners/PulseLoader'

import AIChat from './AIChat'

import X from '../../Images/x-white.svg'

const NavbarChatComponent = ({dimensions,width,route,navigate}) => {
	const [ scroll, setScroll ] = useState(0)
	// const [ pageHeight, setPageHeight ] = useState(0)
	const [ chatDislay, setChatDisplay ] = useState(false)
	const [ history, setHistory ] = useState([])
	const [ processing, setProcessing ] = useState(false)

	useEffect(()=>{
		function handleScroll(e) {
			const position = window.pageYOffset
			setScroll(position)
		}
		window.addEventListener('scroll', handleScroll, { passive: true })
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	},[])

	useEffect(()=>{
		let stored_item = localStorage.getItem('convo_storage')
		if(stored_item !== null && stored_item !== undefined){
			stored_item = JSON.parse(stored_item)
			setHistory(stored_item)
		}
	},[])

	useEffect(()=>{
		if(history.length > 0){
			const chatWindow = document.getElementById('endOfChat_iuwbev98wb89')
			if(chatWindow !== null) chatWindow.scrollTop = chatWindow.scrollHeight
		}
	},[history,chatDislay])


	function chatClicked() {
		setChatDisplay(true)
		document.body.style.overflow = "hidden"
	}
	function closeChat() {
		setChatDisplay(false)
		document.body.style.overflow = "auto"
	}



	let chatWidth = 980
	if(chatWidth > dimensions.width - 20) chatWidth = dimensions.width - 20
	function chatDisplay() {
		let arr = []
		for(let i = 0; i < history.length; i++){
			arr.push(
				<div key={i} style={{whiteSpace:'pre-wrap'}} className='ggl_text_main_color ggl_text_smaller'>
					<div className={history[i]['role'] === 'model' ? 'flexLeft' : 'flexRight'} style={{marginTop:40}}>
						{/*<div style={{width:10,height:48}} className='flexCenter'>
							<div style={{height:8,width:8,borderRadius:8}}></div>
						</div>*/}
						<div style={{padding:12,borderRadius:14}} className={history[i]['role'] === 'model' ? 'darkerBackground fadeIn' : 'aiUserBackground fadeIn flexLeft'}>
							<div style={{maxWidth:chatWidth*0.7}}><div>{history[i]['parts'][0]['text']}</div></div>
						</div>
						<div style={{width:10}}></div>
					</div>
				</div>
			)
		}
		if(processing){
			arr.push(
				<div key={'feh83'}>
					<ClipLoader color={'rgb(190,190,220)'} loading={true} cssOverride={{}} size={10} aria-label="Loading Spinner" data-testid="loader" />
				</div>
			)
		}
		if(arr.length === 0){
			return(
				<div>
					<div className='ggl_text_subheading ggl_text_main_color overflow relative' style={{}}>
						<div className='textAnimateIn absolute textCenterx' style={{width:chatWidth}}>Hi, is there anything I can help you with?</div>
						<div className='noOpacity'>Hi, is there anything I can help you with?</div>
					</div>
					<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:chatWidth}}>
						<div className='textAnimateIn2 absolute textCenterx' style={{width:chatWidth}}>Feel free to ask me about my coding, or have a chat about my music. I'm here to help and I'll do my best to answer your inquiries as best as I can.</div>
						<div className='noOpacity'>Feel free to ask me about my coding, or have a chat about my music. I'm here to help and I'll do my best to answer your inquiries as best as I can.</div>
					</div></div>
				</div>
			)
		}
		return(
			<div>{arr}<div className='space_height_40'></div></div>
		)
	}
	function chatLength() {
		if(history.length === 0) return null
		return(
			<div>
				<div style={{height:28,width:28,background:'rgb(120,90,190)',borderRadius:20,marginTop:-50,marginLeft:-40}} className='absolute ggl_text_main_color ggl_text_smaller flexCenter'>{history.length}</div>
				<div style={{height:10,width:10,background:'rgb(120,90,190)',borderRadius:20,marginTop:-26,marginLeft:-19}} className='absolute ggl_text_main_color ggl_text_smaller flexCenter'></div>
				<div style={{height:5,width:5,background:'rgb(120,90,190)',borderRadius:20,marginTop:-19,marginLeft:-10}} className='absolute ggl_text_main_color ggl_text_smaller flexCenter'></div>
			</div>
		)
	}
	if(route !== ''){
		return(
			<div className='relative' style={{zIndex:20}}>
				{/*<div style={{position:'fixed',top:0,left:0,right:0,bottom:dimensions.height-(10+70),background:'rgb(10,10,16)',borderBottom:'1px solid rgb(20,20,20)'}} className='fadeIn flexHeightCenter'>
					<div style={{width:20}}></div>
					<div className='ggl_text_main_color pointer'>LF</div>
				</div>
				<div style={{top:10,width:dimensions.width}} className='fixed flexCenter'>
					<div onClick={chatClicked}><AIChat width={width} history={[]} setHistory={()=>{}} /></div>
				</div>*/}

				<div style={{position:'fixed',top:0,left:0,right:0,bottom:dimensions.height-(10+70),background:'rgb(10,10,16)',borderBottom:'1px solid rgb(20,20,20)'}} className='fadeIn flexHeightCenter'>
					<div style={{width:20}}></div>
					<div className='ggl_text_main_color pointer flexCenter' style={{height:40,width:40,borderRadius:30,background:'black',border:'1px solid rgb(40,40,40)'}} onClick={()=>{navigate('/')}}>LF</div>
				</div>
				<div style={{top:10,width:dimensions.width}} className='fixed flexCenter pointerNone'>
					<div onClick={chatClicked} className='pointerAuto'><AIChat width={width} history={[]} setHistory={()=>{}} />{chatLength()}</div>
				</div>
				{chatDislay === true
					? <div className='flexCenter fadeInx radialColorBackground fixed' style={{top:0,left:0,right:0,bottom:0}}>
						<div style={{width:chatWidth}}>
							<div style={{height:80}}></div>
							<div style={{height:dimensions.height-100-80,width:chatWidth,overflow:'scroll'}} className='fadeIn' id='endOfChat_iuwbev98wb89'>{chatDisplay()}</div>
							<div className='fadeInslower'><AIChat width={chatWidth} history={history} setHistory={setHistory} setProcessing={setProcessing} /></div>
						</div>
						<div className='fixed' style={{top:0,left:0}}>
							<div onClick={closeChat} className='ggl_text_main_color pointer' style={{padding:16}}><img src={X} alt='X' style={{height:20}} /></div>
						</div>
					</div>
					: null
				}
			</div>
		)
	}

	let nav_height = dimensions.height/2 - scroll
	if(nav_height <= 10) nav_height = 10
	// if(pageHeight !== 0){
	// 	nav_height = pageHeight
	// }
	return(
		<div className='relative' style={{zIndex:20}}>
			{nav_height <= 10
				? <div style={{position:'fixed',top:0,left:0,right:0,bottom:dimensions.height-(nav_height+70),background:'rgb(10,10,16)',borderBottom:'1px solid rgb(20,20,20)'}} className='fadeIn flexHeightCenter'>
					<div style={{width:20}}></div>
					<div className='ggl_text_main_color pointer flexCenter' style={{height:40,width:40,borderRadius:30,background:'black',border:'1px solid rgb(40,40,40)'}}>LF</div>
				</div>
				: null
			}
			<div style={{top:nav_height,width:dimensions.width}} className='fixed flexCenter pointerNone'>
				<div onClick={chatClicked} className='pointerAuto'><AIChat width={width} history={[]} setHistory={()=>{}} />{chatLength()}</div>
			</div>
			<div className='flexCenter textCenterx radialColorBackground' style={{height:dimensions.height-80}}>
				<div style={{width:width}}>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					<div className='ggl_text_hero ggl_text_main_color overflow relative' style={{}}>
						<div className='textAnimateIn absolute textCenterx' style={{width:width}}>Creative <b className='textGradient'>Tech</b></div>
						<div className='noOpacity'>Creative <b className='textGradient'>Tech</b></div>
					</div>
					<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:width}}>
						<div className='textAnimateIn2 absolute textCenterx' style={{width:width}}>Hi, I’m Liam. I apply creative technology solutions to coding, music and architectural rendering.</div>
						<div className='noOpacity'>Hi, I’m Liam. I apply creative technology solutions to coding, music and architectural rendering.</div>
					</div></div>
					<div className='space_height_20'></div>
					<div className='pointerNone' style={{opacity:0}}><AIChat width={width} history={[]} setHistory={()=>{}} /></div>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
				</div>
			</div>
			{/*<div className='flexCenter textCenterx radialColorBackground' style={{height:dimensions.height-80}}>
				<div style={{width:width}}>
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
					<div className='ggl_text_hero ggl_text_main_color overflow relative' style={{}}>
						<div className='textAnimateIn absolute textCenterx' style={{width:width}}>Creative <b className='textGradient'>Tech</b></div>
						<div className='noOpacity'>Creative <b className='textGradient'>Tech</b></div>
					</div>
					<div className='flexCenter'><div className='ggl_text_main_secondary ggl_text_secondary_color overflow relative' style={{width:width}}>
						<div className='textAnimateIn2 absolute textCenterx' style={{width:width}}>Hi, I’m Liam. I apply creative technology solutions to coding music and architectural rendering.</div>
						<div className='noOpacity'>Hi, I’m Liam. I apply creative technology solutions to coding music and architectural rendering.</div>
					</div></div>
					<div className='space_height_20'></div>
					<AIChat width={width} />
					<div className='space_height_60'></div>
					<div className='space_height_60'></div>
				</div>
			</div>*/}

			{chatDislay === true
				? <div className='flexCenter fadeInx radialColorBackground fixed' style={{top:0,left:0,right:0,bottom:0}}>
					<div style={{width:chatWidth}}>
						<div style={{height:80}}></div>
						<div style={{height:dimensions.height-100-80,width:chatWidth,overflow:'scroll'}} className='fadeIn' id='endOfChat_iuwbev98wb89'>{chatDisplay()}</div>
						<div className='fadeInslower'><AIChat width={chatWidth} history={history} setHistory={setHistory} setProcessing={setProcessing} /></div>
					</div>
					<div className='fixed' style={{top:0,left:0}}>
						<div onClick={closeChat} className='ggl_text_main_color pointer' style={{padding:16}}><img src={X} alt='X' style={{height:20}} /></div>
					</div>
				</div>
				: null
			}
		</div>
	)
}

export default NavbarChatComponent